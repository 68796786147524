.crossIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: rotate(45deg);
    cursor: pointer;
    z-index: 3;
}

@media only screen and (max-width: 600px) {
    .crossIcon {
        right: 35px;
    }
}