.container {
  display: flex;
  gap: 3.5rem;
  width: 100%;
}

.item {
  width: 100%;
  height: auto;
  color: var(--darkTextColor);
}

@media only screen and (max-width: 990px) {
  .container {
    display: block;
  }
}