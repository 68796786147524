@import 'src/styles/global.scss';

.applicationsPageContainer {
  margin-block-start: 0px;
  background-color: var(--bgColor);
  overflow-x: hidden;

  aside {
    position: relative;
    width: 100%;
    max-width: calc(100% - 23.1rem);
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .contentContainer {
      margin-block-end: 2rem;
      padding-inline: 3.5rem;
      display: flex;
      flex-direction: column;
      gap: 3.5rem;

      .applicationBtnContainer {
        display: flex;
        gap: 3.5rem;

      }

      .searchContainer {
        .searchColOne {
          input {
            background: var(--primaryColor20) !important;
            color: var(--darkTextColor) !important;
            font-weight: 600;
            border-radius: 4rem;
            border: none !important;

            &::placeholder {
              color: var(--darkTextColor) !important;
              font-weight: 600;
              opacity: 100%;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .searchColTwo {
          text-align: right;
          display: flex;
        }
      }
    }
  }
}

.applicantName {
  @extend .fontTextM;
  background-color: transparent;
  border: hidden;
  color: var(--primaryTextColor);
  text-align: left;
}

.confirmationDialog {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: var(--darkTextColor);
  text-align: left;
  margin-bottom: 20px;
}

.primary {
  color: var(--primaryTextColor);
}

@media only screen and (max-width: 990px) {
  .applicationsPageContainer {
    aside {
      .contentContainer {
        .searchContainer {
          .searchColOne {
            width: 100%;
          }
        }

        .searchColTwo {
          text-align: right;
          margin-top: 2rem;
          width: 100%;
        }
      }
    }
  }
}


@media only screen and (max-width: 990px) {
  .applicationsPageContainer {
    aside {
      .contentContainer {
        .applicationBtnContainer {
          flex-direction: column;
          align-items: center;
          margin-bottom: 3.5rem;
        }

        .filterColOne {
          width: 65%;
        }

        .appBtnColTwo {
          width: 35%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .applicationsPageContainer {
    aside {
      max-width: calc(100% - 7rem);
    }
  }
}