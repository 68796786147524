.crossIcon {
  position: absolute;
  top: 15px;
  right: 30px;
  transform: rotate(45deg);
}

.popupInfo {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  color: var(--primaryVariationTextColor);
  text-align: center;
}

.title {
  font-size: 30px;
  line-height: 34px;
  font-weight: 700;
  font-family: var(--fontNotoSans);
  padding: 20px 0px;
}

.body {
  font-weight: 600;
  color: var(--darkTextColor);

  p {
    text-align: center;
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .info {
    color: var(--darkTextColor);
  }
}

.backgroundImage {
  position: 'relative';
  margin-left: -50px;
  margin-right: -50px;
  height: 50px;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .popupInfo {
    gap: 2rem;
  }

  .title {
    font-size: 30px;
  }
}

.successAnimation {
  background-image: url("../../../../public/assets/feedback/success-1-loop.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: none;
  width: 134px;
  height: 134px;
  margin: auto;
}

@media only screen and (max-width: 990px) {
  .submitBtn {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .successAnimation {
    width: 100px;
    height: 100px;
  }
}