.oneColorCardContainer {
  max-width: 100%;
  padding: 2rem 2.2rem;
  margin: 0rem 0rem 2rem 0;
  position: relative;

  svg {
    position: absolute;
    top: 1.4rem;
    right: 2.3rem;
  }

  h4 {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--primaryVariationTextColor);
  }
}

.activityCount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3.5rem;
  margin-block-start: 1.2rem;
}

.activity {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-bottom: 10px;

  h4 {
    font-weight: 600;
    color: var(--darkTextColor);
    margin: 0;
  }

  h3 {
    font-weight: 600;
    color: var(--darkTextColor);
    margin: 0;
  }

  p {
    font-weight: 600;
    color: var(--darkTextColor);
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  h4 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.noData {
  color: var(--emptyColor);
}

.emptyImg {
  text-align: center;
  height: 100px;

  svg {
    max-width: 16rem;
  }
}