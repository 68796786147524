.formGroup {
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 8px;
    color: var(--darkTextColor);
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.2rem;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    box-sizing: border-box;
  }

  .error {
    border-color: #ff3d3d !important;
  }

  .errorMessage {
    color: #ff3d3d;
    overflow-wrap: break-word;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 5px;
  }

  .custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    border: 1px solid var(--darkTextColor);
    border-radius: 1rem;
    padding: 8px 12px;
    font-size: 16px;
    color: var(--darkTextColor);
    width: 100%;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns%3D%22http://www.w3.org/2000/svg%22 viewBox%3D%220 0 4 5%22%3E%3Cpath fill%3D%22%2349426B%22 d%3D%22M0 0L2 2 4 0z%22/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 10px 10px;
    font-family: var(--fontDMSans);
  }

  .custom-select:focus {
    outline: none;
    border-color: var(--darkTextColor);
    font-family: var(--fontDMSans);
  }
}