.offerInfoPageContainer {
  background-color: var(--bgColor);
  position: relative;
}

.helpContainer {
  padding: 2rem 2.9rem;

  button {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--primaryVariationTextColor);
    background-color: transparent;
    border: none;
    width: auto;
  }
}

.helpCol2 {
  text-align: right;

  button {
    font-size: 1.3rem;
  }
}

.offerInfoInner {
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  padding: 4.5rem 5.7rem;
  max-width: 88.8rem;
  margin: 0 auto 11.5rem;
}

.backIcon {
  svg {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 26px;
  }
}

.moreInfoContainer {
  text-align: center;

  h1 {
    margin: 3.5rem 0 0;
    color: var(--darkTextColor);
    text-align: left;
  }

  p {
    margin: 2.5rem 0 0;
    color: var(--darkTextColor);
    text-align: justify;
  }
}

.footerTransparent {
  background-color: var(--bgColor);
  padding: 2.9rem;
}

.footerCol1 {
  align-self: flex-end;

  ul {
    display: flex;
    gap: 0.5rem;
    padding: 0px;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;

    li {
      a {
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: var(--primaryVariationTextColor);
        position: relative;
      }
    }
  }
}

.footerCol2 {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--primaryVariationTextColor);
  }

  img {
    max-width: 76px;
  }
}

@media only screen and (max-width: 600px) {
  .offerInfoPageContainer {
    padding-inline: 2.9rem;
  }

  .helpContainer {
    padding: 1.5rem 0rem;
  }

  .helpCol1 {
    display: none;
  }

  .offerInfoInner {
    border: none !important;
    max-width: 100% !important;
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .backIcon {
    svg {
      top: -36px;
      left: -11px;
    }
  }

  .offerCard {
    grid-template-areas:
      "logo head head"
      "detail detail detail"
      "btn btn btn";
    row-gap: 2rem;
    border: none !important;
  }

  .offerCardBtn {
    align-items: center;

    button {
      max-width: 10.2rem !important;
    }
  }

  .footerCol2 {
    display: none;
  }
}