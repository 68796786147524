.submittedCount {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 1.6rem 2.5rem;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  gap: 2.5rem;
  margin-block-end: 2rem;

  &:nth-child(1) {
    max-width: 100%;
    margin: 0 auto 2rem 0;
  }

  &:nth-child(2) {
    max-width: 95%;
    margin: 0 auto 2rem 0;
  }

  &:nth-child(3) {
    max-width: 90%;
    margin: 0 auto 2rem 0;
  }

  &:nth-child(4) {
    max-width: 85%;
    margin: 0 auto 0 0;
  }
}

.submittedColOne {
  display: flex;
  gap: 2.5rem;
}

.countContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.35rem;

  h1 {
    margin: 0;
    text-align: center;
    color: var(--darkTextColor);
  }

  p {
    margin: 0;
    text-align: center;
    color: var(--darkTextColor);
  }
}

.logoContainer {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;

  h2 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.emptyImg {
  max-height: "100%";

  img {
    max-width: 6rem;
  }
}

.fadeInSvg {
  animation: fade-in 1s;
}

.fadeOutSvg {
  animation: fade-out 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}