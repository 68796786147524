.searchBlock {
  margin: 4rem 0;
}

.crossIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  transform: rotate(45deg);
  cursor: pointer;
}

.inviteFormContainer {
  margin-block-start: 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  label {
    color: var(--darkTextColor);
  }

  input {
    margin-block-start: 1rem;

    &:focus {
      outline: none;
    }
  }
}

.searchBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 3.4rem;
  margin: 4rem;

  div {
    flex: 1 1 50%;

    &:last-child {
      text-align: right;
    }
  }

  input {
    background: var(--mainViolet20);
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--darkTextColor);

    &::placeholder {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--darkTextColor);
    }

    &:focus {
      outline: none;
    }
  }
}

.inviteFormRow {
  display: flex;
  gap: 3.5rem;

  >div {
    flex: 1 1 50%;
    position: relative;
  }
}

.disabledInput {
  label {
    color: var(--placeholderColor);
  }

  input {
    border-color: var(--placeholderColor) !important;
    color: var(--placeholderColor) !important;
    pointer-events: none;
    user-select: none;
  }
}

.locationBorrowerTable {
  border-radius: 2rem;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
}

.locationBorrowerTableHeader {
  background: var(--primaryColor);
  border-radius: 2rem 2rem 0 0;
  border-bottom: none !important;

  p {
    color: var(--whiteTextColor) !important;
    font-weight: 600 !important;
  }
}

.locationTableRow {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  padding: 1.3rem 2.1rem;
  align-items: center;
  gap: 0.9rem;
  border-bottom: 0.5px solid var(--darkTextColor);
  position: relative;

  p {
    margin: 0;
    font-weight: 500;
    color: var(--darkTextColor);
  }

  button:hover {
    background-color: var(--primaryColor) !important;
    color: var(--whiteTextColor) !important;
  }
}

.locationTableRow:last-child {
  border: none;
}

.primaryLocIcon {
  position: absolute;
  left: 2px;
}

.btnContainer {
  text-align: right;
}