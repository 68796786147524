.popupContainer {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6rem 3.5rem;
  z-index: 3;
  max-width: 76.3rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  max-height: 90vh;

  p {
    color: var(--primaryVariationTextColor);
  }
}

.crossIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  transform: rotate(45deg);
  cursor: pointer;
}

.fieldsContainer {
  display: flex;
  gap: 3.5rem;

  >div {
    flex: 1 1 50%;

    p {
      color: var(--darkTextColor);
      margin: 0;
    }

  }
}

.fieldBlock {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  border: 1px solid var(--darkTextColor);
  border-radius: 1.4rem;
  padding: 1.5rem 2rem;
  margin-top: 1.1rem;
  max-height: 36.2rem;
  overflow-y: auto;

  >div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      li {
        color: var(--darkTextColor);
        cursor: pointer;
      }
    }
  }
}

.fieldsColTwo {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    align-items: flex-start;

    li {
      color: var(--darkTextColor);
      cursor: pointer;
    }
  }
}

.btnContainer {
  text-align: right;

  button {
    &:hover {
      background-color: var(--whiteColor) !important;
      color: var(--primaryTextColor) !important;
    }
  }
}

@media only screen and (max-width: 990px) {
  .popupContainer {
    position: absolute;
    transform: translateX(-50%);
    max-width: 52.3rem;
    max-height: 90vh;
  }

  .fieldsContainer {
    flex-direction: column;
  }
}