.p1 {
  color: var(--primaryVariationTextColor);
  text-align: center;
  margin: 0;
}

.p2 {
  color: var(--primaryVariationTextColor);
  text-align: center;
  margin-block: 3.5rem;
  margin-left: 8rem;
  margin-right: 8rem;
}

.crossIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  transform: rotate(45deg);
  cursor: pointer;
}

.msgTextarea {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  margin-left: 8rem;
  margin-right: 8rem;

  label {
    color: var(--darkTextColor);
  }

  textarea {
    border: 1px solid var(--darkTextColor);
    border-radius: 10px;
  }
}

.btnContainer {
  text-align: center;
  margin-top: 3.5rem;
}

.btnContainer {
  text-align: center;
}

.errorText {
  color: #ff3d3d !important;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0px !important;
  margin-left: 5px !important;
  text-align: left !important;
}