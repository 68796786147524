@import 'src/styles/global.scss';

.topbarWrapper {
  margin-bottom: 30px;
  cursor: pointer;
  width: calc(100% - 40rem - 23.1rem);
  position: fixed;
  top: 6rem;
  background-color: var(--bgColor);
  z-index: 2;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.topbarTab {
  height: 50px;

  p {
    @extend .menu;
    line-height: 50px;
    position: relative;
    text-align: center;
  }
}

.topbarTabActive {
  background-color: var(--primaryColor20Solid);
  border: 1px solid var(--primaryColor40Solid);
}

@media only screen and (max-width: 1200px) {
  .topbarWrapper {
    width: calc(100% - 40rem - 7rem);
  }
}