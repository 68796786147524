// .thanksContainer {
// position: fixed;
// top: 90px;
// left: 50%;
// transform: translateX(-32%);
// display: flex;
// flex-direction: column;
// align-items: center;
// gap: 3.5rem;

// background-image: url("../../public/assets/images/thanksGif.gif");
// background-position: center;
// background-repeat: no-repeat;
// background-size: cover;

// p {
//   text-align: center;
//   color: var(--primaryVariationTextColor);
// }
// }

.confettiAnimation {
  background-image: url("../../public/assets/images/thanksGif.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: none;
}

.buttonCenter {
  text-align: center;
}

.confettiButton {
  margin-block-start: 4.5rem;
}

.thanksText {
  color: var(--primaryVariationTextColor) !important;
  text-align: center;
}

@media only screen and (max-width: 990px) {
  .thanksContainer {
    max-width: 45.5rem;
    transform: translateX(-24%);
  }
}