.miniFeedback {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  text-shadow: 0px 2px 2px rgba(50, 15, 122, 0.25);
  padding: 15px 45px 15px 25px;
  border-radius: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 1.4rem;
  font-weight: 700;
  max-width: 90%;
  z-index: 9999999;
  box-shadow: 0.8rem 0.8rem 1rem var(--primaryColor40);
}

.show {
  opacity: 1;
}

.success {
  background-color: rgb(14, 180, 133);
}

.error {
  background-color: #dc3737;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 10px;
  rotate: 45deg;
}
