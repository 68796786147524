@import 'src/styles/global.scss';

.button {
  width: auto;
  min-width: 14rem;
  background-color: var(--primaryColor);
  border-radius: 4rem;
  border: 2px solid var(--primaryColor);
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  color: var(--whiteTextColor);
  margin: 0;
  text-align: center;

  @extend .fontLabelM;
}

.button.secondary {
  background-color: var(--whiteColor);
  color: var(--primaryTextColor);
}

.button.disabled {
  background-color: var(--whiteColor);
  border: 2px solid var(--disableColor);
  color: var(--disableColor);
  font-weight: 700;
}

.button.small {
  font-size: 1.4rem;
  padding: 0.5rem 1.2rem;
  min-width: auto;
}