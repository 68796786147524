.locationBorrowerContainer {
  background-color: var(--bgColor);
  position: relative;
}

.helpContainer {
  padding: 2rem 2.9rem;

  button {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--primaryVariationTextColor);
    background-color: transparent;
    border: none;
    width: auto;
  }
}

.invitationFormContainer {
  padding-inline: 2.9rem;
  margin-block-end: 5.4rem;
}

.invitationFormInner {
  max-width: 90rem;
  padding: 3.3rem;

  h2 {
    text-align: center;
    margin-block: 3rem;
  }
}

.brandLogo {
  text-align: center;

  img {
    width: auto;
    max-height: 6rem;
  }
}

.locationBorrowerTable {
  border-radius: 2rem;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
}

.locationBorrowerTableHeader {
  background: var(--primaryColor);
  border-radius: 2rem 2rem 0 0;
  border-bottom: none !important;

  p {
    color: var(--whiteTextColor) !important;
    font-weight: 600 !important;
  }
}

.locationTableRow {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  padding: 1.3rem 2.1rem;
  align-items: center;
  gap: 0.9rem;
  border-bottom: 0.5px solid var(--darkTextColor);

  p {
    margin: 0;
    font-weight: 500;
    color: var(--darkTextColor);
  }

  button:hover {
    color: var(--whiteTextColor) !important;
    background-color: var(--primaryColor) !important;
  }
}

.locationTableRow:last-child {
  border: none;
}

.buttonContainer {
  text-align: center;
  margin-top: 3.5rem;

  button:hover {
    background: var(--whiteColor) !important;
  }

  button:hover:enabled {
    color: var(--primaryTextColor) !important;
  }
}

.mobileHeader {
  display: none;
}

@media only screen and (max-width: 990px) {
  .invitationFormInner {
    padding-inline: 6rem;
  }

  .footerCol2 {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .locationBorrowerContainer {
    margin-top: 0px;
  }

  .helpContainer {
    display: none;
  }

  .invitationFormInner {
    padding-inline: 0;
    padding-block-start: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .brandLogo {
    display: none;
  }

  .locationBorrowerTable {
    border-radius: none;
    box-shadow: none;
  }

  .locationBorrowerTableHeader {
    display: none !important;
  }

  .locationTableRow {
    background: var(--whiteColor);
    box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
    border-radius: 2rem;
    border-bottom: none;
    margin-bottom: 2.5rem;
    grid-template-columns: unset;
    grid-template-areas:
      "name btn"
      "address address";
    row-gap: 1rem;
  }

  .locationName {
    grid-area: name;

    p {
      font-weight: 600;
    }
  }

  .locationAddress {
    grid-area: address;
  }

  .locationBtn {
    grid-area: btn;
  }

  .buttonContainer {
    button {
      max-width: 15rem !important;
    }
  }

  .desktopHeader {
    display: none;
  }

  .mobileHeader {
    display: block;
  }
}