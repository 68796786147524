.popupContainer {
  position: fixed;
  top: 110px;
  left: 95%;
  transform: translateX(-95%);
  padding: 4rem 3rem;
  max-width: 57.3rem;
  z-index: 3;
  max-height: 90vh;

  button {
    &:hover {
      background: var(--primaryColor) !important;
      color: var(--whiteTextColor) !important;
    }
  }
}

.crossIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  transform: rotate(45deg);
  cursor: pointer;
}

.notificationContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-block: 2.5rem;
  padding-inline-end: 1rem;
  max-height: 28.6rem;
  overflow-y: auto;
}

.notification {
  padding: 1.9rem 2.4rem;
  background-color: var(--whiteColor);
  box-shadow: 0.3rem 0.3rem 1rem var(--primaryColor20);
  border-radius: 2rem;
  position: relative;

  p {
    color: var(--darkTextColor);
    margin: 0;
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  &:hover {
    .markReadContainer {
      display: flex;
    }
  }
}

.markReadContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--primaryColor20);
  border-radius: 2rem;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-end;
  padding-inline: 2.2rem;
  display: none;
}

.notRead {
  border: 0.2rem solid var(--secondaryColor);
}

@media only screen and (max-width: 990px) {
  .popupContainer {
    position: absolute;
    transform: translateX(0);
    left: 0;
    max-height: 90vh;
  }
}