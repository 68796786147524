.agreement {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid var(--primaryColor);
    text-align: left;
    padding: 8px;
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--darkTextColor);
  }

  th {
    background-color: var(--primaryColor20);
    font-weight: 600;
    color: var(--primaryVariationTextColor)
  }

  a {
    font-weight: 600;
    color: var(--primaryTextColor);
  }

  b {
    color: var(--primaryVariationTextColor);
  }
}