@import 'src/styles/global.scss';

.pageContainer {
  margin-block-start: 0px;
  background-color: var(--bgColor);
  overflow-x: hidden;

  aside {
    position: relative;
    width: 100%;
    max-width: calc(100% - 23.1rem);
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .contentContainer {
      margin-block-end: 2rem;
      padding-inline: 3.5rem;
      display: flex;
      flex-direction: column;
      gap: 3.5rem;

      .btnContainer {
        display: flex;
        gap: 3.5rem;
      }

      .searchContainer {
        .searchColOne {
          input {
            background: var(--primaryColor20) !important;
            color: var(--darkTextColor) !important;
            font-weight: 600;
            border-radius: 4rem;
            border: none !important;


            &::placeholder {
              color: var(--darkTextColor) !important;
              font-weight: 600;
              opacity: 100%;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .searchColTwo {
          text-align: right;

        }
      }
    }
  }
}

.merchantPartnerName {
  @extend .fontTextM;
  background-color: transparent;
  border: hidden;
  color: var(--primaryTextColor);
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .pageContainer {
    aside {
      .contentContainer {
        .searchContainer {
          .searchColTwo {
            margin-top: 2rem;
          }
        }

      }
    }
  }
}


@media only screen and (max-width: 990px) {
  .pageContainer {
    aside {
      .contentContainer {
        .searchContainer {
          .searchColTwo {
            column-count: 2;
          }
        }

      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .pageContainer {
    aside {
      max-width: calc(100% - 7rem);
    }
  }
}