.borrowerExperienceContainer {
  background-color: var(--bgColor);
  position: relative;
}

.helpContainer {
  padding: 2rem 2.9rem 0 !important;

  button {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--primaryVariationTextColor);
    background-color: transparent;
    border: none;
    width: auto;
  }
}

.helpCol2 {
  text-align: right;

  button {
    font-size: 1.4rem;
  }
}

.invitationFormContainer {
  padding-inline: 2.9rem;
  margin-block-end: 2rem;
}

.invitationFormInner {
  max-width: 84.1rem;

  h1 {
    text-align: center;
  }
}

.formBlock {
  margin-block-start: 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.backIcon {
  svg {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 26px;
  }
}

.formInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.5rem;
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  label {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--darkTextColor);
  }

  input,
  select {
    margin-block-start: 1rem;
  }

  input:focus,
  select:focus {
    outline: none;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;

  img {
    width: 17px;
    aspect-ratio: 1;
  }
}

.passwordInputField {
  position: relative;

  img {
    position: absolute;
    width: 20px;
    right: 4%;
    top: 44%;
    cursor: pointer;
    z-index: 1;
    background-color: transparent;
  }
}

.uploadLogoContainer {
  p {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: var(--darkTextColor);
    margin: 0;
  }
}

.uploadContainer {
  margin-block-start: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-inline: 2rem;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  border: 0.1rem solid var(--darkTextColor);
  border-radius: 1rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--darkTextColor);

  label {
    background-color: transparent;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: var(--primaryTextColor);
    cursor: pointer;
  }

  #choosenFile {
    margin-left: 0.3rem;
    font-family: sans-serif;
  }
}

.ssnInner {
  justify-content: center;
}

.logoContainer {
  background: var(--whiteColor);
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  min-height: 25.4rem;
  text-align: center;
}

.formBtnContainer {
  text-align: right;

  button:hover {
    background-color: var(--whiteColor) !important;
    color: var(--primaryTextColor) !important;
  }

  button:focus {
    outline: none;
  }
}

.nextBtnContainer {
  flex: 1;
  align-self: flex-end;
}

.finalBtnContainer {
  display: flex;
  justify-content: flex-end;
  gap: 3.6rem;

  button:first-child:hover {
    background-color: var(--primaryColor) !important;
    color: var(--whiteTextColor) !important;
  }
}

.verifyDOBContainer {
  padding-block-start: 6.5rem;
  padding-block-end: 8.5rem;

  // change the name here and where it is used, because there is already a class with name .formBlock in this file
  .formBlock {
    max-width: 403px;
    margin: 3.5rem auto 0;
  }
}


.progressBar {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;

  div[role="progressbar"] {
    background-color: var(--greenColor);
    border-radius: 0px 2.1rem 2.1rem 0px;
  }
}

.borrowerFooter {
  background-color: var(--bgColor);
  padding-inline: 2.9rem;
  padding-block-end: 4.6rem;
}

.footerCol1 {
  align-self: flex-end;

  ul {
    display: flex;
    gap: 0.5rem;
    padding: 0px;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;

    li {
      a {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: var(--primaryVariationTextColor);
        position: relative;
        cursor: pointer;
      }
    }
  }
}

.footerCol2 {
  text-align: center;
  cursor: pointer;

  p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--primaryVariationTextColor);
  }

  img {
    max-width: 76px;
  }
}

.smallBorrowerFooter {
  padding-block-end: 2rem;
}

.contactInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
  margin-top: 15px;

  p {
    margin: 0px 0px 0px 0px;
  }

  p:first-child {
    color: var(--primaryVariationTextColor);
  }

  p:last-child {
    color: var(--darkTextColor);
    font-size: 1.5rem !important;
  }
}

.addressSelector {
  background-color: var(--primaryColor20);
  padding: 30px;
  border-radius: 10px;
}

.buttonRight {
  text-align: right;
}

@media only screen and (max-width: 990px) {
  .helpContainer {
    padding: 3rem 2.9rem 0;
  }

  .invitationFormInner {
    padding-inline: 7.2rem;
  }

  .footerCol2 {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .helpContainer {
    padding: 3rem 2.5rem;
  }

  .invitationFormInner {
    padding-inline: 2rem;
    padding-block-start: 2rem;
    background-color: transparent;
    box-shadow: none;
  }

  .formInner {
    flex-direction: column;
    gap: 2.6rem;

    p {
      max-width: 100%;
    }
  }

  .formField {
    width: 100%;
  }

  .formBtnContainer {
    text-align: center;

    button {
      max-width: 24rem !important;
    }
  }

  .nextBtnContainer {
    width: 100%;
  }

  .backIcon {
    svg {
      top: -16px;
      left: -4px;
    }
  }

  .footerCol2 {
    display: none;
  }

  .smallBorrowerFooter {
    padding-block-end: 2rem !important;
  }

  .footerRow {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .footerCol2 {
    display: block;
  }
}

.numberPrefix {
  position: relative;

  span {
    font-size: 1.6rem;
    color: var(--darkTextColor);
    line-height: 2.2rem;
    font-weight: 500;
    position: absolute;
    left: 10px;
    top: 58%;
    transform: translateY(-50%);
  }
}

.errorText {
  color: #ff3d3d !important;
  font-size: 1.2rem;
  font-weight: 500;
  position: absolute;
  bottom: -33px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .helpContainer {
    padding: 1.5rem 2.5rem;
  }

  .borrowerExperienceContainer {
    background-color: var(--whiteColor);
  }
}