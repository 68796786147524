.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primaryColor);
  z-index: 0;
}

.body {
  font-weight: 600;
  text-align: center;
  font-size: 1.8rem;
  line-height: 3rem;
  color: var(--darkTextColor);
  padding: 20px 40px 0 40px;
}

.imageSvg {
  text-align: center;
}

.title {
  font-size: 30px;
  line-height: 34px;
  font-weight: 600;
  color: var(--darkTextColor);
  padding: 40px 0 0 0;
  text-align: center;
}
