.tab-navigation {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0;
  height: 49px;
  justify-content: space-evenly;
  position: fixed;
  text-align: center;
  top: 60px;
  width: calc(100% - 63rem);
  z-index: 5;
}

.tab-navigation.shadow {
  box-shadow: -5px 5px 10px var(--primaryColor20);
}

.tab-content {
  background-color: #F8F8FF;
  padding: 30px calc(30px + 40rem) 30px 30px;
}

@media only screen and (max-width: 1200px) {
  .tab-navigation {
    width: calc(100% - 47rem);
  }
}
