@import 'src/styles/global.scss';

.stage {
  width: 25%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.line {
  width: 100%;
  background-color: var(--primaryColor);
  z-index: 0;
  position: absolute;
  height: 3px;
  left: 50%;
  top: 24px;
}

.icon_wrapper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.status {
  @extend .fontText;
  color: var(--darkTextColor);
  margin-top: 1rem;
}

.date {
  @extend .fontInfoBig;
  color: var(--darkTextColor);
}

.amount {
  @extend .fontText;
  color: var(--primaryTextColor);
}

.rejected {
  color: red;
}