.footerContainer {
  background-color: var(--primaryColor);
  padding: 3rem 2.5rem;

  p {
    color: var(--whiteTextColor);
  }
}

.footerCol1 {
  p:first-child {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0px;
    justify-content: center;
    gap: 0.6rem;
    align-items: center;
    flex-wrap: wrap;
    margin: 2.5rem 0 0;

    li {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: center;
      color: var(--whiteTextColor);

      a {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: center;
        color: var(--whiteTextColor);
      }
    }
  }
}

.footerCol2 {
  text-align: center;
  margin-block-start: 2rem;
  cursor: pointer;

  p {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
  }

  img {
    width: 100%;
    max-width: 87px;
  }
}

.row {
  gap: 2.5rem;
}

@media only screen and (max-width: 550px) {
  .footerCol1 {
    p {
      text-align: center;
    }

    ul {
      margin: 2.5rem 0 !important;

      li {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
  }

  .footerCol2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.1rem;
    padding-block-end: 1.6rem;

    p {
      margin: 5px;
    }
  }
}