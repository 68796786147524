.innerWindowContainer {
  max-width: 80rem;
  margin: 0 auto;
  background-color: var(--whiteColor);
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  padding: 5rem;
  position: relative;
  width: 100%;
  margin-top: 20px;
}

@media only screen and (max-width: 700px) {
  .innerWindowContainer {
    background-color: #66000000;
    box-shadow: none;
    padding: 2rem;
  }
}