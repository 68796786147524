.search {
  background: var(--primaryColor20);
  color: var(--darkTextColor);
  font-weight: 600;
  border-radius: 4rem;
  border: none;
  width: 100%;
  padding-inline: 2rem;
  height: 45px;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
  font-family: inherit;
}