.popupHeader {
  background: var(--primaryColor);
  border-radius: 2rem 2rem 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 3.5rem;
  min-height: 60px;

  div:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;

    img {
      max-height: 30px;
      width: auto;
    }

  }

  svg {
    cursor: pointer;
  }

  svg,
  h3 {
    color: var(--whiteTextColor);
    // margin: 0 -10px 0 0;
    margin: 0;
  }
}

.iconBackground {
  border-radius: 22px;
  background-color: var(--whiteColor);
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center !important;
}

.backIcon {
  svg {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 26px;
  }
}

.popupInfo {
  padding: 3.5rem;
  background-color: var(--whiteColor);
  border-radius: 0 0 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.contactInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;

  p {
    margin: 0;
  }

  p:first-child {
    color: var(--primaryVariationTextColor);
  }

  p:last-child {
    color: var(--darkTextColor);
    font-size: 1.5rem !important;
  }
}

.center {
  text-align: center;
  color: var(--darkTextColor);
  font-size: 1.5rem !important;
}