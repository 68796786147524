@import 'src/styles/global.scss';

.card {
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  background-color: var(--whiteColor);
  padding: 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 3rem;
}

.withoutHeader {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.cardHeader {
  background-color: var(--primaryColor20Solid);
  height: 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 30px;
}

.cardHeaderSetAmount {
  background-color: var(--primaryColor20Solid);
  min-height: 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 30px;
}

.headerWrapper {
  display: flex;

  p {
    width: 100%;
    display: inline-block;
    margin: 0px;
    line-height: 60px;
    color: var(--primaryTextColor);
  }

  p:first-child {
    text-align: left;
    width: 45%;
  }

  p:last-child {
    text-align: right;
    width: 55%;
  }
}

.headerWrapperCancel {
  display: flex;

  p {
    width: 100%;
    display: inline-block;
    margin: 0px;
    line-height: 60px;
    color: var(--primaryTextColor);
  }
}

.headerWrapperSetAmount {
  display: flex;
  padding: 1rem 0rem;
  align-items: center;

  p {
    width: 100%;
    display: inline-block;
    margin: 0px;
    line-height: 2.2rem;
    color: var(--primaryTextColor);
    text-wrap: balance;
  }
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.line {
  width: 100%;
  background-color: var(--primaryColor);
  z-index: 0;
  position: absolute;
  height: 3px;
  left: 50%;
  top: 24px;
}

.stage {
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.icon_wrapper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.status {
  @extend .fontText;
  color: var(--darkTextColor);
  margin-top: 1rem;
}

.date {
  @extend .fontInfoBig;
  color: var(--darkTextColor);
}

.loan_amount {
  @extend .fontText;
  color: var(--primaryTextColor);
}

.offerDetailsWrapper {
  text-align: center;
  line-height: 60px;
  width: 100%;
  text-align: right;
}

.offerDetails {
  @extend .fontMobile2;
  color: var(--darkTextColor);
  display: inline;
}

.important {
  color: var(--primaryTextColor);
}

.breadcrumbsRefundWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  h2 {
    margin-bottom: 0px !important;
  }
}

.rejected {
  color: red;
}

.refundInProcessSection {
  background-color: var(--primaryVariationColor);
  height: 60px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  color: var(--whiteTextColor);

  p {
    margin: 0px;
  }
}