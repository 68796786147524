@import 'src/styles/global.scss';

.app-tracker-wrapper {
  display: flex;
  border-radius: 2rem;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  background-color: var(--whiteColor);
  padding: 2rem;
  justify-content: space-around;
  flex-direction: column;
  color: var(--primaryVariationTextColor);
  gap: 2rem;
  min-height: 222px;
}

.app-tracker-line {
  position: absolute;
  height: 2px;
  background-color: var(--primaryColor);
  width: 100%;
  top: 23px;
  left: 50%;
  z-index: 1;
}

.app-tracker-line-last {
  position: absolute;
}

.app-tracker {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.app-tracker-step {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  width: 100%;
}

.app-tracker-step-icon {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: var(--primaryColor);
  z-index: 2;
}

.app-tracker-step-title {
  color: var(--primaryVariationTextColor);
  
  @extend .fontTextM;
}

.app-tracker-step-count {
  color: var(--primaryTextColor);
}

.app-tracker-step-more-info {
  color: var(--primaryVariationTextColor);
  
  @extend .fontInfoBig;
}