.viewApplicantPageContainer {
  margin-block-start: 0px;
  background-color: var(--bgColor);

  aside {
    position: relative;
    width: 100%;
    max-width: calc(100% - 23.1rem);
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .contentContainer {
      margin-block-end: 2rem;
      padding-inline: 3.5rem 3.5rem;
      display: flex;
      flex-direction: column;
      gap: 3.5rem;
      width: calc(100% - 45rem);

      .pageTitle {
        color: var(--primaryVariationTextColor);
      }

      h2 {
        margin-bottom: 2rem;
      }
    }
  }
}

.tableContainer {
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  overflow-x: auto;
}

@media only screen and (max-width: 1200px) {
  .viewApplicantPageContainer {
    aside {
      max-width: calc(100% - 7rem);

      .contentContainer {
        padding-inline-end: 6.5rem;
      }
    }
  }
}