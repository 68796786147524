.sidebarContainer {
  background-color: var(--primaryColor);
  position: fixed;
  width: 100%;
  max-width: 23.1rem;
  height: 100%;
}

.siteLogo {
  text-align: center;
  padding: 1.5rem;
  margin-bottom: 50px;
  height: auto;

  img {
    max-width: 200px;
    width: auto;
    max-height: var(--logoMaxHeight);
  }
}

.menuContainer {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    li {
      list-style: none;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.55rem;
        padding-block: 0.9rem;
        max-width: 90%;
        border-radius: 0px 2rem 2rem 0px;

        div:first-child {
          flex: 1 1 40%;
          text-align: right;

          svg {
            text-shadow: 0px 2px 4px rgba(50, 15, 122, 0.25);
          }
        }

        div:last-child {
          flex: 1 1 60%;

          p {
            color: var(--whiteTextColor);
            margin: 0;
          }
        }
      }
    }
  }
}

.financeBusinessBackground,
.dtmProcessBackground {
  text-align: center;
  position: absolute;
  bottom: 100px;
  background-color: var(--primaryVariationColor);
  width: 100%;
  height: 50px;
}

.dtmProcessBackground {
  bottom: 200px;
}

.dtmProcessCloseButton {
  position: absolute;
  top: 10px;
  right: 5px;
  transform: rotate(45deg);
  cursor: pointer;
  z-index: 3;
  // display: inline-block;
}

.financeBusiness,
.dtmProcess {
  p {
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 50px;
    color: var(--whiteTextColor);
    display: inline;
  }

  svg {
    color: var(--whiteTextColor);
  }

  img {
    max-width: 93px;
  }
}

.bottomLogo {
  text-align: center;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);

  p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--whiteTextColor);
  }

  img {
    max-width: 93px;
  }
}


@media only screen and (max-width: 1200px) {
  .sidebarContainer {
    max-width: 7rem;
    box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  }

  .siteLogo {
    img {
      max-width: 4.5rem;
    }
  }

  .menuContainer {
    ul {
      li {
        a {
          padding-left: 22px;
          border-radius: 0px;
          max-width: 100%;

          div:last-child {

            p {
              display: none;
            }
          }
        }
      }
    }
  }

  .bottomLogo {
    display: none;
  }

  .sidebarContainer:hover {
    max-width: 23.1rem;
    z-index: 9;

    .siteLogo {
      img {
        max-width: 13.3rem;
      }
    }

    .menuContainer {
      ul {
        li {
          a {
            padding-left: 0;
            border-radius: 0px 2rem 2rem 0px;
            max-width: 90%;

            div:last-child {

              p {
                display: flex;
              }
            }
          }
        }
      }
    }

    .bottomLogo {
      display: flex;
    }
  }
}