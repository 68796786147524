.partnerInvitationContainer {
  background-color: var(--bgColor);
  position: relative;
}

.helpContainer {
  padding: 2rem 2.9rem;

  button {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--primaryVariationTextColor);
    background-color: transparent;
    border: none;
    width: auto;
  }
}

.invitationFormContainer {
  padding-inline: 2.9rem;
  margin-block-end: 5.4rem;
}

.invitationFormInner {
  width: 100%;
  max-width: 88rem;
  margin: 0 auto;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  padding: 4.5rem 8.2rem;
  position: relative;
  background-color: var(--whiteColor);

  svg {
    position: absolute;
    top: 20px;
    left: 24px;
    cursor: pointer;
  }

  h1 {
    text-align: center;
  }
}

.formBlock {
  margin-block-start: 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.formInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5.6rem;
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  label {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: var(--darkTextColor);
  }

  input,
  select {
    margin-block-start: 1rem;
    padding-inline: 2rem;
    height: 50px;
    width: 100%;
    border: 0.1rem solid #2f2d6c;
    border-radius: 1rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--darkTextColor);
  }

  input:focus,
  select:focus {
    outline: none;
  }

  select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../../../../public/assets/images/arrowDown.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 2rem) center;
    background-size: 1rem;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    width: 17px;
    aspect-ratio: 1;
  }
}

.passwordInputField {
  position: relative;

  img {
    position: absolute;
    width: 20px;
    right: 4%;
    top: 46%;
    cursor: pointer;
    z-index: 1;
    background-color: var(--whiteColor);
  }
}

.uploadLogoContainer {
  p {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: var(--darkTextColor);
    margin: 0;
  }
}

.uploadContainer {
  margin-block-start: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-inline: 2rem;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  border: 0.1rem solid #2f2d6c;
  border-radius: 1rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--darkTextColor);

  label {
    background-color: transparent;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: var(--primaryTextColor);
    cursor: pointer;
  }

  #choosenFile {
    margin-left: 0.3rem;
    font-family: sans-serif;
  }
}

.passRequirements {
  h3 {
    font-weight: 700 !important;
    font-size: 1.4rem !important;
    line-height: 1.8rem !important;
  }

  ul {
    margin-block-start: 2rem;

    li {
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--primaryVariationTextColor);
      font-weight: 500;
    }
  }
}

.logoContainer {
  background: var(--whiteColor);
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  min-height: 25.4rem;
}

.formBtnContainer {
  text-align: right;

  button:hover {
    background-color: var(--whiteColor) !important;
    color: var(--primaryColor) !important;
  }

  button:focus {
    outline: none;
  }
}

.nextBtnContainer {
  flex: 1;
  align-self: flex-end;
}

.finalBtnContainer {
  display: flex;
  justify-content: flex-end;
  gap: 3.6rem;

  button:first-child:hover {
    background-color: var(--primaryColor) !important;
    color: var(--whiteTextColor) !important;
  }
}

.progressBar {
  position: sticky;
  width: 100%;
  bottom: 0;

  div[role="progressbar"] {
    background-color: var(--greenColor);
    border-radius: 0px 2.1rem 2.1rem 0px;
  }
}

@media only screen and (max-width: 600px) {
  .invitationFormInner {
    padding-inline: 2.5rem;
  }

  .formInner {
    flex-direction: column;
    gap: 2.6rem;
  }

  .formField {
    width: 100%;
  }

  .formFieldEmail {
    flex: 0 !important;
  }

  .formBtnContainer {
    button {
      max-width: 100% !important;
    }
  }

  .nextBtnContainer {
    width: 100%;
  }
}