@import 'src/styles/global.scss';

.infoCards {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
}


// @media only screen and (max-width: 1400px) {
//   .infoCards {
//     height: 450px;
//   }
// }

// @media only screen and (max-width: 1250px) {
//   .infoCards {
//     height: 550px;
//   }
// }

.contentContainer {
  margin-block-end: 2rem;
  padding-inline: 3.5rem;
  padding-left: 6rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  width: calc(100% - 40rem);
  margin-top: 6rem;

  .tableContainer {
    overflow-x: auto;
    border-radius: 2rem;
    box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
    background-color: var(--whiteColor);
    padding: 0;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  text-align: right;
}

.titleCalendarContainer {
  margin-top: auto !important;
  margin-bottom: auto;
}

.titleCalendar {
  margin-top: auto;
  margin-bottom: auto;
}

.calendarRange {
  z-index: 0;
}

.approval {
  border-width: 2px;
  min-width: 48%;
  padding: 4rem;
  background-color: var(--whiteColor);
  border-radius: 20px;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 3rem;
}

.approvalText {
  @extend .menu;
  line-height: 20px;
  color: var(--darkTextColor);
  // white-space: nowrap;
  flex: none;
  margin: 10px;
}

.approvalButtons {
  display: flex;
  gap: 4rem;
  width: 100%;
  justify-content: center;
}