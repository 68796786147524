@import 'src/styles/global.scss';

.settingsPageContainer {
  margin-block-start: 0px;
  background-color: var(--bgColor);

  aside {
    position: relative;
    width: 100%;
    max-width: calc(100% - 23.1rem);
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

.contentContainer {
  margin-block-end: 2rem;
  padding-inline: 9.5rem 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  font-size: 1.6rem;
  font-weight: 700;
}

.pageTitle {
  color: var(--primaryVariationTextColor);
  margin: 0;
}

.tableContainer {
  overflow-x: auto;
  border-radius: 2rem;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  background-color: var(--whiteColor);
  scrollbar-color: var(--primaryColor40Solid) var(--whiteColor);

  >div {
    min-width: 110rem !important;
  }
}

.applicantInfoContainer {
  display: flex;
  gap: 3.5rem;

  >div {
    width: 100%;
    flex: 1 1 50%;
  }
}

.infoContainer {
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
}

.infoHeader {
  background: var(--primaryColor);
  border-radius: 2rem 2rem 0 0;
  padding: 1rem 3.5rem;
  display: flex;
  justify-content: flex-start;
  gap: 2.5rem;
  align-items: center;

  img {
    width: 4.5rem;
    aspect-ratio: 1;
    object-fit: cover;
  }

  h3 {
    color: var(--whiteTextColor);
    margin: 0;
  }
}

.infoBody {
  padding: 3.5rem;
  background-color: var(--whiteColor);
  border-radius: 0 0 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
  position: relative;
}

.editInfo {
  position: absolute;
  top: 20px;
  right: 22px;
  cursor: pointer;
}

.infoRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  p {
    margin: 0;

    &:first-child {
      color: var(--primaryVariationTextColor);
    }

    &:last-child {
      color: var(--darkTextColor);
    }
  }
}

.infoTogglerow {
  gap: 7rem;
}

.infoImgBody {
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  min-height: 15.5rem;

  img {
    max-width: 27.9rem;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.approval {
  border-width: 2px;
  position: absolute;
  top: 55Px;
  right: 0px;
  min-width: 48%;
  display: flex;
  justify-content: space-evenly;
  padding: 1.5rem;
  background-color: var(--whiteColor);
  border-bottom-left-radius: 20px;
  align-items: center;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  gap: 2rem;
}

.approvalText {
  @extend .menu;
  width: 40%;
  line-height: 20px;
  color: var(--darkTextColor);
  // white-space: nowrap;
  flex: none;
  margin: 10px;
}

.accountInformationTitle {
  height: 100% !important;
  margin: auto !important;
}

@media only screen and (max-width: 990px) {
  .contentContainer {
    padding-inline-end: 6.5rem;
    padding-bottom: 11rem;
  }

  .applicantInfoContainer {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .approval {
    min-width: 50%;
  }

  .approvalText {
    width: 30%;
  }
}

@media only screen and (max-width: 1200px) {
  .settingsPageContainer {
    aside {
      max-width: calc(100% - 7rem);
    }
  }
}