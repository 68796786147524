$banner-height: 751px;
$banner-width: 1300px;

.landingPage {
  margin-block-start: 0px;
}

// Default banner
.bannerContainer {
  height: $banner-height;
  background-color: var(--primaryColor);
  display: flex;
  align-items: flex-start;
  position: relative;
  justify-content: center;

  h1 {
    color: var(--whiteTextColor);
    font-size: 5.1rem !important;
    line-height: 6.4rem !important;
    font-family: var(--fontNotoSans);
    font-weight: 800 !important;
    text-shadow: 0px 2px 4px rgba(50, 15, 122, 0.25);
    text-align: left !important;
    max-width: 757px;
    margin-top: 100px;
    padding-right: 60px;
  }

  p {
    color: var(--textColor);
    font-size: 2.3rem;
    line-height: 3.2rem;
    margin-block: 2rem;
    font-weight: 600;
    max-width: 608px;
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: $banner-width;
    width: $banner-width;
    background-repeat: no-repeat;
    height: $banner-height;
    background-size: 550px;
    background-position: 735px 200px;
    justify-content: center;
    gap: 20px;
    position: relative;
  }

  .wl-hero-image {
    position: absolute;
    top: 281px;
    left: 690px;
    width: 590px;
    height: auto;
  }
}

.topRightCornerLink {
  color: var(--textColor);
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
}

.secondSection {
  padding: 10rem 2.5rem;
  background-color: var(--bgColor);

  h3 {
    font-weight: 700 !important;
    font-size: 3rem !important;
    line-height: 9rem !important;
    font-family: var(--fontNotoSans);
    color: var(--primaryVariationTextColor);
  }
}

.featuresContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.5rem;
  margin-block-start: 4.7rem;
}

.featureBlock {
  box-shadow: 8px 8px 20px var(--primaryColor20);
  border-radius: 1rem;
  padding: 5rem 4rem;
  text-align: center;
  background-color: var(--whiteColor);
  height: 100%;
  width: 100%;

  img,
  svg {
    width: 100%;
    height: auto;
    max-width: 60px;
  }

  h4 {
    font-weight: 600 !important;
    font-size: 2.2rem !important;
    line-height: 2.8rem !important;
    color: var(--primaryVariationTextColor);
    font-family: var(--fontDMSans);
    margin-block-start: 2.7rem;
  }
}

.howItWorkContainer {
  margin-block-start: 4rem;
  box-shadow: 8px 8px 20px var(--primaryColor20);
  border-radius: 1rem;
}

.howItWorkCol {
  background-color: var(--whiteColor);
  padding: 5.5rem 5rem !important;
  border-radius: 1rem 0px 0px 1rem;

  h3 {
    font-weight: 700 !important;
    font-size: 3.2rem !important;
    line-height: 4rem !important;
    font-family: var(--fontNotoSans);
  }
}

.stepsWork {
  display: flex;
  flex-direction: column;
  gap: 7.6rem;
  margin-block-start: 6.4rem;
}

.step {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5rem;

  p {
    font-weight: 600;
    font-size: 1.9rem;
    line-height: 2.5rem;
    color: var(--primaryVariationTextColor);
  }

  p:first-child {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
    color: var(--primaryTextColor);
    padding: 1rem 1.7rem;
    border-radius: 50%;
    box-shadow: 0px 2px 3px rgba(50, 15, 122, 0.15);
  }
}

.one {
  padding: 1rem 1.9rem !important;
}

.howItWorkImg {
  border-radius: 0px 1rem 1rem 0px;
  background-image: url("../../public/assets/images/howitworks.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.buttonPrimary {
  background-color: var(--primaryColor);
  color: var(--textColor);
  border-radius: 40px;
  padding-block: 1.2rem;
  padding-inline: 4.6rem;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 180px;
  text-align: center;
}

.buttonPrimaryVariation {
  background-color: var(--primaryVariationColor);
  color: var(--textColor);
  border-radius: 40px;
  padding-block: 1.2rem;
  padding-inline: 4.6rem;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 180px;
  text-align: center;

  &:hover {
    color: var(--darkTextColor);
    background-color: var(--whiteColor);
  }
}

.borderRadiusLarge {
  border-radius: 2.5rem;
}

.max1400 {
  .bannerContainer {
    h1 {
      max-width: 639px;
    }

    p {
      max-width: 492px;
    }

    .content {
      max-width: $banner-width;
      width: $banner-width;
      padding: 0 100px 0 100px;
    }
  }
}

.max1300 {
  .bannerContainer {
    height: 650px;

    h1 {
      max-width: 651px;
      font-size: 4rem !important;
      line-height: 6rem !important;
    }

    p {
      max-width: 603px;
      font-size: 2.1rem;
      line-height: 3.1rem;
    }

    .content {
      height: 650px;
      max-width: $banner-width;
      width: $banner-width;
      padding: 0 100px 0 100px;
      background-size: 450px;
      background-position: 680px 160px;
    }

    .wl-hero-image {
      position: absolute;
      top: 243px;
      left: 681px;
      width: 512px;
      height: auto;
    }
  }
}

.max1150 {
  .bannerContainer {
    height: 600px;

    h1 {
      max-width: 499px;
      font-size: 3.7rem !important;
      line-height: 5rem !important;
    }

    p {
      max-width: 460px;
      font-size: 1.8rem;
      line-height: 2.7rem;
    }

    .content {
      height: 600px;
      max-width: $banner-width;
      width: $banner-width;
      padding: 0 100px 0 100px;
      background-size: 380px;
      background-position: 600px 180px;
    }

    .wl-hero-image {
      position: absolute;
      top: 191px;
      left: 598px;
      width: 476px;
      height: auto;
    }
  }
}

.max990 {
  .bannerContainer {
    height: 500px;

    h1 {
      max-width: 444px;
      font-size: 3.1rem !important;
      line-height: 4rem !important;
    }

    p {
      max-width: 402px;
      font-size: 1.5rem;
      line-height: 2.5rem;
    }

    .content {
      height: 500px;
      max-width: $banner-width;
      width: $banner-width;
      padding: 0 100px 0 100px;
      background-size: 330px;
      background-position: 550px 130px;
    }

    .wl-hero-image {
      position: absolute;
      top: 185px;
      left: 524px;
      width: 396px;
      height: auto;
    }
  }

  .secondSection {
    padding: 5rem 2.5rem;
  }

  .featuresContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .featureBlock {
    padding: 3rem;

    h4 {
      font-size: 1.6rem !important;
      line-height: 2rem !important;
    }
  }

  .howItWorkInner {
    flex-direction: column-reverse;
  }

  .howItWorkCol {
    padding: 4rem 3rem;
    border-radius: 1rem;
  }

  .stepsWork {
    gap: 5.5rem;
    margin-block-start: 3.4rem;
  }

  .step {
    gap: 2.4rem;
  }

  .howItWorkImg {
    min-height: 61.2rem;
    border-radius: 1rem 1rem 0 0;
  }
}

.max900 {
  .bannerContainer {
    height: 500px;

    h1 {
      max-width: 433px;
      font-size: 3rem !important;
      line-height: 3.7rem !important;
    }

    p {
      max-width: 387px;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    .content {
      height: 500px;
      max-width: $banner-width;
      width: $banner-width;
      padding: 0 50px 0 50px;
      background-size: 300px;
      background-position: 440px 150px;
    }

    .wl-hero-image {
      position: absolute;
      top: 196px;
      left: 468px;
      width: 366px;
      height: auto;
    }
  }
}

.max750 {
  .bannerContainer {
    height: 500px;

    h1 {
      max-width: 380px;
      font-size: 3rem !important;
      line-height: 3.7rem !important;
    }

    p {
      max-width: 280px;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    .content {
      height: 500px;
      max-width: $banner-width;
      width: $banner-width;
      padding: 0 50px 0 50px;
      background-size: 260px;
      background-position: 340px 200px;
    }

    .wl-hero-image {
      position: absolute;
      top: 259px;
      left: 408px;
      width: 295px;
      height: auto;
    }
  }

  .howItWorkInner {
    flex-direction: column-reverse;
  }

  .howItWorkCol {
    padding: 4rem 3rem;
    border-radius: 1rem;

    h3 {
      font-size: 2.6rem !important;
      line-height: 3rem !important;
    }
  }

  .stepsWork {
    gap: 5.5rem;
    margin-block-start: 3.4rem;
  }

  .step {
    gap: 2.4rem;
  }

  .howItWorkImg {
    min-height: 36.2rem;
    border-radius: 1rem 1rem 0 0;
  }
}

.max600 {
  .bannerContainer {
    height: 760px;

    h1 {
      max-width: 100%;
      font-size: 3rem !important;
      line-height: 3.7rem !important;
      padding-right: 0;
    }

    p {
      max-width: 100%;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    .content {
      margin-top: 30px;
      height: 730px;
      max-width: $banner-width;
      width: $banner-width;
      padding: 0 50px 0 50px;
      background-size: 300px;
      background-position: 150px 420px;
      justify-content: flex-start;
    }

    .wl-hero-image {
      position: absolute;
      top: 371px;
      left: 193px;
      width: 322px;
      height: auto;
    }
  }

  .secondSection {
    h3 {
      font-size: 2.6rem !important;
      line-height: 3rem !important;
    }
  }

  .howItWorkInner {
    flex-direction: column-reverse;
  }

  .howItWorkCol {
    padding: 4rem 3rem;
    border-radius: 1rem;

    h3 {
      font-size: 2.6rem !important;
      line-height: 3rem !important;
    }
  }

  .stepsWork {
    gap: 5.5rem;
    margin-block-start: 3.4rem;
  }

  .step {
    gap: 2.4rem;
  }

  .howItWorkImg {
    min-height: 36.2rem;
    border-radius: 1rem 1rem 0 0;
  }
}

.max480 {
  .bannerContainer {
    height: 760px;

    h1 {
      max-width: 100%;
      font-size: 3.3rem !important;
      line-height: 3.7rem !important;
      text-align: justify;
      padding-right: 0;
    }

    p {
      max-width: 100%;
      font-size: 1.4rem;
      line-height: 2.2rem;
      text-align: justify;
    }

    .content {
      margin-top: 0px;
      height: 760px;
      max-width: $banner-width;
      width: $banner-width;
      padding: 0 50px 0 50px;
      background-size: 280px;
      background-position: 70px 470px;
      justify-content: flex-start;
    }

    .wl-hero-image {
      position: absolute;
      top: 556px;
      left: 83px;
      width: 245px;
      height: auto;
    }
  }

  .secondSection {
    h3 {
      font-size: 2.6rem !important;
      line-height: 3rem !important;
    }
  }

  .howItWorkInner {
    flex-direction: column-reverse;
  }

  .howItWorkCol {
    padding: 4rem 3rem;
    border-radius: 1rem;

    h3 {
      font-size: 2.6rem !important;
      line-height: 3rem !important;
    }
  }

  .stepsWork {
    gap: 5.5rem;
    margin-block-start: 3.4rem;
  }

  .step {
    gap: 2.4rem;
  }

  .howItWorkImg {
    min-height: 36.2rem;
    border-radius: 1rem 1rem 0 0;
  }
}

.max380 {
  .bannerContainer {
    height: 850px;

    h1 {
      max-width: 100%;
      font-size: 3.3rem !important;
      line-height: 3.7rem !important;
      text-align: justify;
    }

    p {
      max-width: 100%;
      font-size: 1.4rem;
      line-height: 2.2rem;
      text-align: justify;
    }

    .content {
      margin-top: 0px;
      height: 850px;
      max-width: $banner-width;
      width: $banner-width;
      padding: 0 30px 0 30px;
      background-size: 269px;
      background-position: 20px 550px;
      justify-content: flex-start;
    }

    .wl-hero-image {
      position: absolute;
      top: 526px;
      left: 81px;
      width: 215px;
      height: auto;
    }
  }

  .secondSection {
    padding: 2.5rem;

    h3 {
      font-size: 2.6rem !important;
      line-height: 3rem !important;
    }
  }

  .howItWorkInner {
    flex-direction: column-reverse;
  }

  .howItWorkCol {
    padding: 4rem 3rem;
    border-radius: 1rem;

    h3 {
      font-size: 2.6rem !important;
      line-height: 3rem !important;
    }
  }

  .stepsWork {
    gap: 5.5rem;
    margin-block-start: 3.4rem;
  }

  .step {
    gap: 2.4rem;
  }

  .howItWorkImg {
    min-height: 36.2rem;
    border-radius: 1rem 1rem 0 0;
  }
}