.borrowerFooter {
  background-color: var(--bgColor);
  padding: 2rem;
  margin-bottom: 0px;
}

.footerCol1 {
  align-self: flex-end;

  ul {
    display: flex;
    gap: 0.5rem;
    padding: 0px;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;

    li {
      a {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: var(--primaryVariationTextColor);
        position: relative;
      }
    }
  }
}