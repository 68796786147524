@import 'src/styles/global.scss';

.lineWithIcon {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1.5rem;
  margin: 3rem 0rem 3rem 0rem;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: normal;

  span {
    @extend .fontTextMBig;
  }
}

.textPrimary {
  color: var(--primaryTextColor);
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: normal;
}

.editLine {
  cursor: pointer;
}