.panel {
  width: 100%;
  padding: 30px 30px 0 30px;
  position: relative;
}

.panel-title {
  line-height: 22.5px;
  color: var(--primaryVariationTextColor);
  text-align: center;
  margin-bottom: 25px;
}

.edit-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.custom-content {
  text-align: center;
}

.bottomBanner {
  position: absolute;
  bottom: 0px;
  text-align: center;
  left: 0px;
  // right: -30px;
  width: calc(100%);
  background-color: var(--primaryColor);
  border-bottom-left-radius: 20px;

  p {
    text-align: center;
    margin: 20px;
    color: var(--whiteTextColor);
  }
}