.menuContainer {
  position: absolute;
  top: 60px;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 0px 0px 2rem 0;
  background-color: var(--whiteColor);
  z-index: 1;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    li {
      color: var(--primaryVariationTextColor);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.9rem;
      cursor: pointer;
      padding: 1.5rem 2rem;

      p {
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        margin: 0;
        display: none;
      }

      &:last-child:hover {
        border-radius: 0 0 2rem 0;

      }

      &:hover {
        background-color: var(--primaryColor20);

      }
    }
  }

  &:hover {
    p {
      display: block;
    }
  }
}