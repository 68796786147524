@import 'src/styles/global.scss';

.buttonContainer {
  position: relative;
}

.button {
  width: 16rem;
  background: var(--primaryColor);
  border-radius: 4rem;
  border: 2px solid var(--primaryColor);
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 48px;
  color: var(--whiteTextColor);
  margin-right: 0px;
  text-align: center;

  @extend .fontLabelM;
}

.button:hover {
  transform: scale(1);
}

.options {
  position: absolute;
  top: 48px;
  left: 0;
  width: 16rem;
  background-color: var(--whiteColor);
  border: 1px solid var(--primaryColor);
  border-radius: 0 0 2rem 2rem;
  z-index: 2;
}

.option {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  line-height: 48px;
  height: 48px;
  color: var(--primaryVariationTextColor);
  border-top: 1px solid var(--primaryColor);
}

.option:hover {
  background-color: var(--primaryColor20);
}

.option:last-child:hover {
  border-radius: 0 0 2rem 2rem;
}