.contactInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
  margin-top: 15px;

  p {
    margin: 0px 0px 0px 0px;
  }

  p:first-child {
    color: var(--primaryVariationTextColor);
  }

  p:last-child {
    color: var(--darkTextColor);
    font-size: 1.5rem !important;
  }
}

.addressSelector {
  background-color: var(--primaryColor20);
  padding: 30px;
  border-radius: 10px;
}

.buttonRight {
  text-align: right;
}