.popupContainer {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6rem 3.5rem;
  z-index: 3;
  max-width: 76.3rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  max-height: 90vh;
}

.crossIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  transform: rotate(45deg);
  cursor: pointer;
}

.nameFieldContainer {
  display: flex;
  justify-content: space-between;

  >div {
    flex: 1 1 50%;

    input {
      margin-top: 1rem;
    }
  }
}

.btnContainer {
  text-align: right;
  align-self: flex-end;

  button {
    &:hover {
      background-color: var(--whiteColor) !important;
      color: var(--primaryTextColor) !important;
    }
  }
}

.reportInfo {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;

    li {
      color: var(--primaryVariationTextColor);

      span {
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .popupContainer {
    position: absolute;
    transform: translateX(-50%);
    max-width: 46.3rem;
    max-height: 90vh;
  }
}