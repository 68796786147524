.offerSelectedPageContainer {
  background-color: var(--bgColor);
  position: relative;
}

.helpContainer {
  padding: 2rem 2.9rem;

  button {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--primaryVariationTextColor);
    background-color: transparent;
    border: none;
    width: auto;
  }
}

.helpCol2 {
  text-align: right;

  button {
    font-size: 1.3rem;
  }
}

.invitationFormContainer {
  padding-inline: 2.9rem;
  margin-block-end: 5.4rem;
}

.invitationFormInner {
  max-width: 84.1rem;

  h1 {
    text-align: center;
    margin-block-start: 3.5rem;
  }

  p {
    color: var(--primaryVariationTextColor);
    text-align: center;
    padding: 20px;
  }
}

.brandLogo {
  text-align: center;

  img {
    max-width: 137px;
  }
}

.undertitle {
  color: var(--darkTextColor);
  text-align: center;
}

.moneyImg {
  text-align: center;
  margin-block-start: 4.5rem;

  img {
    max-width: 177px;
  }
}

.footerTransparent {
  background-color: var(--bgColor);
  padding: 2.9rem;
}

.footerCol1 {
  align-self: flex-end;

  ul {
    display: flex;
    gap: 0.5rem;
    padding: 0px;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;

    li {
      a {
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: var(--primaryVariationTextColor);
        position: relative;
      }
    }
  }
}

.footerCol2 {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--primaryVariationTextColor);
  }

  img {
    max-width: 76px;
  }
}

@media only screen and (max-width: 600px) {
  .helpContainer {
    padding: 1.5rem 2.5rem;
  }

  .helpCol1 {
    display: none;
  }

  .invitationFormInner {
    padding-inline: 0;
    padding-block-start: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .formInner {
    flex-direction: column;
    gap: 2.6rem;
  }

  .formField {
    width: 100%;
  }

  .formBtnContainer {
    text-align: center;

    button {
      max-width: 24rem !important;
    }
  }

  .nextBtnContainer {
    width: 100%;
  }

  .backIcon {
    svg {
      top: -36px;
      left: -11px;
    }
  }

  .borrowerFooter {
    .footerCol2 {
      display: none;
    }
  }

  .smallBorrowerFooter {
    padding-block-end: 2rem !important;
  }

  .footerRow {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .footerCol2 {
    display: block;
  }
}