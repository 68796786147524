@import 'src/styles/global.scss';

.confirmation {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 4rem;
  color: var(--primaryVariationTextColor);
  margin: 0;
  text-align: center;
  margin-bottom: 35px;
}

.buttons {
  display: flex;
  gap: 3.5rem;
  justify-content: center;
}

.crossIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: rotate(45deg);
  cursor: pointer;
}

.stepLabel {
  @extend .fontMobile;
  position: absolute;
  top: 15px;
  right: 70px;
  margin: 0px;
  margin-top: 9px;
  color: var(--primaryTextColor);
  z-index: 1;
}