.headerInner {
  background-color: var(--whiteColor);
  padding-block: 1.3rem;
  padding-inline: 2.9rem;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  border-radius: 0 0 3.1rem 0;
  z-index: 1;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0;
  min-height: var(--navigationBarMargin);
}

.initials {
  margin: 0;
}

.borderRadius {
  border-radius: 2rem 0rem 2rem 0rem;
}

@media only screen and (max-width: 600px) {
  .headerInner {
    width: 100%;
    border-radius: 0;
    padding-inline: 2rem;
    position: unset;
  }
}