:global(#merchantSummaryWrapper) {
  display: flex;
  flex-direction: column;
  height: calc(100% - 8.2rem);
  // height: calc(100% - 0rem);
  position: absolute;
  right: 0;
  box-shadow: -2px 2px 20px 0px rgba(99, 94, 247, 0.15);
  transform: translateX(40rem);
  transition: all 0.4s ease;
}

:global(#merchantSummaryWrapper.merchantSummaryActive) {
  transform: translateX(0px);
}

.cardsWrapper2 {
  position: absolute;
  right: 0px;
  margin-left: auto;
  height: calc(100% - 6rem);
  display: flex;
  margin-top: 6rem;
}

.cardsWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: -3rem;
  right: 0;
  width: 45rem; // for ApplicationSummaryCard
  position: sticky;
  margin-left: auto;
  bottom: 0;
  margin-top: auto;
  min-height: calc(100vh - 6rem);
  // min-height: calc(max(100% - 6rem, 100vh - 6rem)); // 100% - 6rem if this card is bigger than "aside" and 100vh - 6rem if it is smaller

  div:last-child {
    border-bottom-left-radius: 20px;
  }
}

.stickySibling {
  flex: 1;
}

.card {
  background-color: var(--whiteColor);
  right: 0;
  padding: 30px;
  padding-top: 40px;
  flex: 3;
  position: relative;

  h2 {
    text-align: center;
  }
}

.cardMerchantAccountTab {
  flex: 20;
}

.cardUsersLocationsTab {
  flex: 1;
}

.cardExperienceSettingsTab {
  flex: 5;
}

.card2 {
  background-color: var(--primaryColor20Solid);
  right: 0;
  padding: 30px;
  padding-top: 40px;
  flex: 2;
  position: relative;

  h2 {
    text-align: center;
  }
}

.card2MerchantAccountTab {
  flex: 9;
}

.card2ExperienceSettingsTab {
  flex: 6;
  background-color: var(--primaryColor10Solid);
}

.card2ExperienceSettingsTabPartner {
  flex: 15;
  background-color: var(--primaryColor10Solid);
}

.card3 {
  background-color: var(--primaryColor20Solid);
  right: 0;
  padding: 30px;
  padding-top: 40px;
  flex: 9;
  position: relative;

  h2 {
    text-align: center;
  }
}

.buttonsWrapper {
  // height: 100%;
  min-height: 60px;
}

.buttons {
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.resendOffers {}

.merchantInformationCard {
  // margin-top: 6rem;
  width: 40rem;
}

.logo {
  width: auto;
  margin: auto;
  max-height: 10rem;
  max-width: 25rem;
  display: block;
  text-align: center;
  margin-top: 3rem;
}

.editInfo {
  position: absolute;
  top: 20px;
  right: 22px;
  cursor: pointer;
}

.moreInfoWrapper {
  width: 100%;
  text-align: center;
  margin-block-start: 5rem;
}

.disabled {
  background-color: var(--blurColor);
}