.locationContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.7rem;
  margin-block-start: 3.5rem;

  img {
    width: auto;
  }

  p {
    color: var(--primaryVariationTextColor);
    margin: 0;
    text-transform: capitalize;
  }
}

.editLocationContainer {
  margin-block-start: 0.8rem;
}