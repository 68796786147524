@import 'src/styles/global.scss';

.pageTitle {
  @extend .fontBig2;
  color: var(--primaryVariationTextColor);
  margin-bottom: 4rem;
  text-align: center;
  text-wrap: balance;
}

.instructions {
  margin: 3rem 0rem;
}

.recalculationForm {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 0 0;
  align-items: flex-end;
  position: relative; // so that validation errors are displayed correctly

  div {
    max-width: 22rem;
  }

  label {
    @extend .fontMobile;
    margin-bottom: 0.5rem;
  }
}

.recalculationWrapper {
  background-color: var(--primaryColor10Solid);
  padding: 2rem 3rem 3rem 3rem;
  border-radius: 2rem;
}

.tableContainer {
  margin-top: 4rem;
}

.sendDenyWrapper {
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
  gap: 4rem;
}