.panels-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100% - 6rem);
  display: flex;
  margin-top: 6rem;
  margin-left: auto;
  z-index: 6;
  box-shadow: -5px 5px 10px var(--primaryColor20);
  border-bottom-left-radius: 20px;
}

.panels {
  display: flex;
  flex-direction: column;
  position: sticky;
  right: 0;
  bottom: 0;
  width: 40rem;
  margin-left: auto;
  margin-top: auto;
  min-height: calc(100vh - 6rem);
}