.offerCardContainer {
  background: var(--whiteColor);
  box-shadow: 8px 8px 10px var(--primaryColor10Solid);
  border-radius: 10px;
  display: grid;
  grid-template-areas:
    "logo head head btn"
    "logo detail detail btn";
  column-gap: 2rem;
  position: relative;
  padding: 2.2rem;
  align-items: center;
}

.offerCardLabel {
  background-color: var(--primaryVariationColor);
  padding: 0.9rem 2.9rem;
  border-radius: 1rem 1rem 0rem 0rem;
  position: absolute;
  top: -34px;
  left: 0px;

  h4 {
    color: var(--whiteTextColor);
    margin: 0;
    font-size: 1.4rem !important;
    font-family: var(--fontDMSans);
    font-weight: 500 !important;
  }
}

.offerCardLogo {
  grid-area: logo;
  display: flex;
  align-items: center;
  height: 90px;
  //box-shadow: 0px 2px 4px rgba(50, 15, 122, 0.25);
  padding-right: 2rem;
  //border-radius: 45px;
  //border-right: 2px solid var(--primaryColor20Solid);
  margin: auto;

  img {
    width: 100px;
  }
}

.border {
  grid-area: logo;
  display: flex;
  align-items: center;
  padding-right: 13rem;
  border-right: 2px solid var(--primaryColor20Solid);
  margin: -2rem auto;

  img {
    width: 100px;
  }
}

.offerCardInfoHeading {
  grid-area: head;

  h3 {
    color: var(--darkTextColor);
    font-size: 2rem !important;
  }
}

.offerInfoContainer {
  grid-area: detail;
  display: inline-grid;
  //grid-template-columns: repeat(3, auto);
  gap: 4.5rem;
  margin-block-start: 1rem;
}

.info {
  text-align: left;

  h4 {
    font-weight: 600 !important;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: var(--darkTextColor);
  }

  span {
    font-weight: 600 !important;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  p {
    margin: 0;
    color: var(--darkTextColor);
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
  }
}

.offerCardBtn {
  grid-area: btn;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    padding: 0.9rem 1.6rem;
  }

  button:first-child:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }

  button:last-child:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }
}

.productType {
  color: var(--darkTextColor);
  font-size: 1.1rem;
  font-weight: 600;
  padding-bottom: 5px;
}

@media only screen and (max-width: 800px) {
  .offerCardContainer {
    grid-template-areas:
      "logo head head"
      "detail detail detail"
      "btn btn btn";
    align-items: center;
    row-gap: 2rem;
  }

  .offerInfoContainer {
    margin: 0;
    gap: 0.5rem;
  }

  .offerCardBtn {
    flex-direction: row;

    button:first-child {
      flex: 1 1 50%;
    }

    button:last-child {
      flex: 1 1 50%;
    }
  }

  .border {
    grid-area: logo;
    display: flex;
    align-items: center;
    height: 80px;
    padding-right: 11rem;
    border-right: 2px solid var(--primaryColor20Solid);
    border-bottom: 2px solid var(--primaryColor20Solid);
    margin: -2rem 0rem -2rem -2rem;

    img {
      width: 100px;
    }
  }

  .offerCardInfoHeading {
    margin: 0rem -2.2rem -0.8rem -2rem;
    border-bottom: 2px solid var(--primaryColor20Solid);
    padding: 0rem 0.2rem 2rem 1.5rem;
  }

  .offerCardContainer {

    box-shadow: 0px 4px 20px var(--primaryColor20Solid);
    padding: 0 2rem 2rem 2rem;
  }
}

@media only screen and (max-width: 560px) {

  .offerCardInfoHeading {
    margin: 0rem -2.2rem -2rem -2rem;
    padding: 0rem 0.2rem 1rem 1.5rem;

    h3 {
      font-size: 1.8rem !important;
    }
  }
}

@media only screen and (max-width: 520px) {

  .border {

    height: 102px;
  }


}