@import 'src/styles/global.scss';

input[type=text],
input[type=password],
input[type=email] {
  width: 100%;
  padding-inline: 2rem;
  height: 45px;
  border: 0.1rem solid var(--darkTextColor);
  border-radius: 1rem;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--darkTextColor);

  &:focus {
    outline: none;
  }
}

input[type=text].v2,
input[type=password].v2,
input[type=email].v2 {
  border: none;
  text-align: center;
  @extend h3;
}

input.v2:disabled {
  background-color: var(--primaryColor10);
  caret-color: transparent;
}

input.input-error {
  border-color: #ff3d3d !important;
}

.errorText {
  color: #ff3d3d !important;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 0.25rem;
}

input.prefix {
  color: black;
}