@import 'src/styles/global.scss';

.stage {
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  margin: 1.5rem 0rem;
}

.stageDetailsWrapper {
  display: flex;
  flex-direction: column;
}

.statusAndAmountWrapper {
  display: flex;
  gap: 0.5rem;
  margin-block-start: 1rem;
}

.icon_wrapper {
  // margin-left: auto;
  // margin-right: auto;
  // position: relative;
  // margin-right: 2rem;
}

.status {
  @extend .fontText;
  color: var(--darkTextColor);
  // margin-top: 1rem;
}

.amount {
  color: var(--primaryTextColor);
}

.date {
  @extend .fontInfoBig;
  color: var(--darkTextColor);
}

.loan_amount {
  @extend .fontText;
  color: var(--primaryTextColor);
}

.line {
  width: 3px;
  height: 100%;
  background-color: var(--primaryColor);
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 19px;
}