.graphContainer {
  background: var(--whiteColor);
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  padding: 2rem 0rem;
  margin: 0 0 2rem;

  h4 {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--primaryVariationTextColor);
    padding-inline: 2rem;
    padding-block-end: 2rem;
  }

  .graphInnerContainer {
    width: 100%;
    height: 22.5rem;
    position: relative;
    padding: 0px 10px;
  }

  .financeActivity {
    max-width: 100%;
    padding: 2rem 2.2rem;
    margin-block-start: 2rem;
    position: relative;

    svg {
      position: absolute;
      top: 1.4rem;
      right: 2.3rem;
    }

    h4 {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
      color: var(--blueColor);
    }

    .activityCount {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 3.5rem;
      margin-block-start: 1.2rem;

      .activity {
        display: flex;
        gap: 1rem;
        align-items: center;

        p,
        h4 {
          font-weight: 600;
          color: var(--darkBlue);
          margin: 0;
        }

        p {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        h4 {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
    }
  }

  .emptyImg {
    text-align: center;

    img {
      max-width: 16rem;
    }
  }
}

.graphContainer.wrapped {
  box-shadow: unset;
  border-radius: unset;
  padding: 0;
  margin: 0;
  background-color: transparent;
}