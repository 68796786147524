.setupMerchantContainer {
  background-color: var(--bgColor);
  position: relative;
  padding-inline: 2.9rem;
}

.helpContainer {
  padding: 2rem 2.9rem;

  button {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--primaryVariationTextColor);
    background-color: transparent;
    border: none;
    width: auto;
  }
}

.contentContainer {
  max-width: 84rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-shadow: var(--whiteColor15);
  border-radius: 2rem;

  >div {
    text-align: center;
    flex: 1 1 50%;

    h1 {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3rem;
      margin: 0;
      text-align: center;
    }

    h3 {
      margin: 3.5rem 0;
      text-align: center;
    }
  }
}

.colOne {
  background-color: var(--primaryColor);
  border-radius: 2rem 0 0 2rem;
  padding: 6.5rem 5rem;

  h1 {
    color: var(--whiteTextColor);
  }

  h3 {
    color: var(--whiteTextColor);
  }

  button {
    &:hover {
      background-color: var(--primaryColor) !important;
      color: var(--whiteTextColor) !important;
      border-color: var(--whiteColor) !important;
    }
  }
}

.colTwo {
  background-color: var(--whiteColor);
  border-radius: 0 2rem 2rem 0;
  padding: 6.5rem 6rem;

  button {
    &:hover {
      background-color: var(--whiteColor) !important;
      color: var(--primaryTextColor) !important;
    }
  }
}

@media only screen and (max-width: 990px) {
  .invitationFormInner {
    padding-inline: 7.2rem;
  }
}

@media only screen and (max-width: 600px) {
  .helpContainer {
    padding: 1.5rem 2.5rem;
  }

  .helpCol1 {
    display: none;
  }

  .contentContainer {
    flex-direction: column;
    margin-bottom: 5rem;
  }

  .colOne {
    border-radius: 2rem 2rem 0 0;
  }

  .colTwo {
    border-radius: 0 2rem 2rem 0;
  }
}