.question {
  color: var(--primaryVariationTextColor);
  text-align: center;
}

.subtitle {
  font-family: var(--fontDMSans);
  font-weight: var(--semiBold);
  font-size: 2rem;
  margin-top: 2rem;
  text-align: center;
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.svgWrapper {
  margin: 30px;
  text-align: center;
}

@media only screen and (max-width: 990px) {
  .popupContainer {
    position: absolute;
    transform: translateX(-50%);
    max-width: 48rem;
    max-height: 90vh;
  }
}