.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 80%;
  max-height: 87%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 20px;
  background-color: var(--whiteColor);
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  padding: 5rem;
  z-index: 50;
}

.popupInner {
  top: 80px;
  transform: translateX(-37%);
}