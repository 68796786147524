.applicationsPageContainer {
  margin-block-start: 0px;
  background-color: var(--bgColor);

  aside {
    position: relative;
    width: 100%;
    max-width: calc(100% - 23.1rem);
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

@media only screen and (max-width: 1200px) {
  .applicationsPageContainer {
    aside {
      max-width: calc(100% - 7rem);
    }
  }
}