@import 'src/styles/global.scss';

.progress-chart-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0;
}

.progress-chart-percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 700;
  color: var(--primaryTextColor);
}

.progress-chart-background {
  stroke: var(--secondaryColor);
  fill: transparent;
  stroke-opacity: 0.4;
}

.progress-chart-foreground {
  stroke: var(--secondaryColor);
  fill: transparent;
  stroke-linecap: round;
}

.progress-chart-title {
  color: var(--primaryVariationTextColor);
  
  @extend .fontTextM;
}