@import 'src/styles/global.scss';

.step1 {
    text-align: center;
    padding: 2rem;

    h1 {
        color: var(--primaryVariationTextColor);
        margin: 3rem 0rem;
        margin-top: 0;
    }

    p {
        @extend .fontLabel;
        margin: 2rem 0rem;
        text-align: center;
        color: var(--darkTextColor);
    }

    li {
        @extend .fontLabel;
        color: var(--darkTextColor);
        text-align: left;
    }
}

.svgWrapper {
    margin: 30px;
    text-align: center;
}