@import 'src/styles/global.scss';

.stats-card {
  width: 100%;
  border-radius: 2rem;
  background-color: var(--whiteColor);
  padding: 20px;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  display: flex;
  align-items: center;
  gap: 2rem;
}

.stats-card-icon {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.stats-card-main-info {
  color: var(--primaryTextColor);
  font-weight: 700;
  font-size: 20px;
}

.stats-card-description {
  color: var(--primaryVariationTextColor);
  
  @extend .fontTextM;
}

.stats-card-icon.primary {
  background-color: var(--primaryColor);
}

.stats-card-icon.secondary {
  background-color: var(--secondaryColor);
}

.stats-card-wrapper {
  display: flex;
  justify-content: center;
}