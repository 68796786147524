.passwordMinRequirements {
  h3 {
    font-weight: 700 !important;
    font-size: 1.4rem !important;
    line-height: 1.8rem !important;
  }

  p {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--primaryVariationTextColor);
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      background-size: 1.7rem;
      background-position: left center;
      background-repeat: no-repeat;
      color: var(--primaryVariationTextColor);
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
      padding: 5px 23px;
    }
  }
}