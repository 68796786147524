.combined-activity-chart-shadown {
  box-shadow: 1.8rem 1.8rem 2rem -1rem var(--primaryColor20);
  border-radius: 1rem 1rem 2rem 2rem;
}

.combined-activity-chart {
  display: flex;
  border-radius: 0 0 2rem 2rem;
  background-color: var(--whiteColor);
  flex-direction: column;
  color: var(--primaryVariationTextColor);
  gap: 3rem;
  padding-top: 20px;
}

.combined-activity-chart-tabs {
  display: flex;
  flex-direction: row;
  gap: 0;
  height: 49px;
  width: 100%;
  position: relative;
}

.combined-activity-chart-tab {
  color: var(--primaryVariationTextColor);
  font-size: 15px;
  font-weight: 700;
  background-color: var(--primaryColor10Solid);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.combined-activity-chart-tab.first {
  box-shadow: inset -1rem -1rem 1rem -1rem var(--primaryColor20);
}

.combined-activity-chart-tab.last {
  box-shadow: inset 1rem -1rem 1rem -1rem var(--primaryColor20);
}

.combined-activity-chart-tab.active {
  background-color: var(--whiteColor);
  box-shadow: unset;
}