.color-pallete-wrapper {
  height: 43px;
  width: 100%;
  display: flex; 
  justify-content: space-evenly;
}

.color-pallete-item {
  height: 100%;
  width: 100%;
}

.color-pallete-item.first {
  border-radius: 10px 0 0 10px;
}

.color-pallete-item.last {
  border-radius: 0 10px 10px 0;
}
