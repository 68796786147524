.progress-chart-row {
  display: flex;
  border-radius: 2rem;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  background-color: var(--whiteColor);
  padding: 2rem;
  justify-content: space-around;
  flex-direction: column;
  color: var(--primaryVariationTextColor);
  gap: 0;
  min-height: 222px;
}

.progress-chart-items {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}