@import 'src/styles/global.scss';

.line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;
  min-height: 30px;
  margin-bottom: 18px;
  left: 0;
}

.line-label {
  @extend .fontTextRegBig;
  line-height: 18.75px;
  color: var(--primaryVariationTextColor);
  width: 90%;
  word-wrap: break-word;
}