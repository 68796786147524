.dashboardPageContainer {
  margin-block-start: 0px;

  aside {
    position: relative;
    width: 100%;
    max-width: calc(100% - 23.1rem);
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--bgColor);

    .contentContainer {
      margin-block-end: 2rem;
      padding-inline: 3.5rem;
      display: flex;
      flex-direction: column;
      gap: 3.5rem;

      .filterContainer {
        .filterColOne {
          display: flex;
          gap: 3.5rem;
        }

        .filterColTwo {
          position: relative;
        }
      }

      .infoSectionContainer {
        .infoSectionColOne {
          display: flex;
          flex-direction: column;
        }

        .infoSectionColTwo {
          .activityContainer {
            display: flex;
            justify-content: flex-start;
            gap: 2rem;

            >div {
              width: 100%;
              flex: 1 1 50%;
              padding: 2rem;
              display: flex;
              flex-direction: column;
              gap: 1.2rem;
              position: relative;

              .appIcon {
                position: absolute;
                right: 12px;
              }

              h4 {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: var(--primaryVariationTextColor);
                margin: 0;
              }

              .activityRow {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;

                p {
                  margin: 0;
                }

                .noData {
                  color: var(--emptyColor);
                }
              }
            }
          }
        }
      }

      .leaderBoardContainer {
        h2 {
          margin-block-end: 2.5rem;
        }

        .dataTable {
          border-radius: 2rem;
          box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);

          .dataTableHeader {
            background: var(--primaryColor);
            border-radius: 2rem 2rem 0 0;
            border-bottom: none !important;

            p {
              color: var(--whiteTextColor) !important;
              font-weight: 600 !important;
            }
          }

          .tableContent {
            max-height: 20.3rem;
            overflow-y: auto;
          }

          .dataTableRow {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding: 1.3rem 2.1rem;
            align-items: center;
            gap: 0.9rem;
            border-bottom: 0.5px solid var(--darkTextColor);

            p {
              margin: 0;
              font-weight: 500;
              color: var(--darkTextColor);
            }

            button:hover {
              background-color: var(--primaryColor) !important;
              color: var(--whiteTextColor) !important;
            }
          }

          .dataTableRow:last-child {
            border: none;
          }
        }

        .bigDataTable {

          .dataTableHeader,
          .dataTableRow {
            grid-template-columns: repeat(7, 1fr);
          }

          .tableContent {
            max-height: 30.3rem;
            overflow-y: auto;
          }
        }
      }
    }
  }
}

.dashboardRow {
  padding-top: 30px;
  gap: 2rem;
}

@media only screen and (max-width: 990px) {
  .dashboardPageContainer {
    aside {
      .contentContainer {
        .filterContainer {
          .filterColOne {
            width: 60%;
          }

          .filterColTwo {
            width: 40%;

            >div {
              >div {
                >div {
                  margin: 0 auto;
                }
              }
            }
          }
        }

        .infoSectionContainer {
          .infoSectionRow {
            gap: 3.5rem;
          }
        }

        .leaderBoardContainer {
          .leaderBoardRow {
            gap: 3.5rem;

            .bigDataTableContainer {
              overflow-x: scroll;

              .bigDataTable {
                min-width: 89.4rem;
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1200px) {
  .dashboardPageContainer {
    aside {
      max-width: calc(100% - 7rem);
    }
  }
}