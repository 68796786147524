.headerContainer {
  background-color: var(--primaryColor);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.headerInnerCol {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 0 !important;
  box-shadow: 0rem 0.3rem 0.4rem var(--primaryColor20);

  img {
    max-width: 100%;
    max-height: var(--logoMaxHeight);
    width: auto;
    height: auto;
    margin: 0px 5px;
  }

  .instructionsWrapper {
    display: flex;
    align-items: center;
  }

  p {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: right;
    color: var(--whiteTextColor);
    margin: 0 2rem 0 3rem;
    display: inline-block;
  }

  button {
    display: none;
    background-color: transparent !important;
    padding: 0 !important;
    width: auto !important;
    color: var(--whiteTextColor) !important;
    border: none !important;
  }
}

.headerLogos {
  min-width: 22rem;
  padding: 1.7rem 2rem;
  border-bottom-right-radius: 2rem;
  min-height: 65px;
  display: flex;
  align-items: center;
  max-width: 30rem;
}

@media only screen and (max-width: 920px) {
  .headerInner {
    p {
      max-width: 300px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .headerContainer {
    background-color: var(--primaryColor);
  }

  .headerInnerCol {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    img {
      max-width: 133px;
    }

    p {
      display: none;
    }
  }
}