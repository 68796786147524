@import 'src/styles/global.scss';

.offersContainer {
  background-color: var(--bgColor);
  position: relative;
  padding-inline: 2.2rem;
}

.helpContainer {
  padding: 2rem 2.9rem;

  button {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--primaryVariationTextColor);
    background-color: transparent;
    border: none;
    width: auto;
  }
}

.helpCol2 {
  text-align: right;

  button {
    font-size: 1.3rem;
  }
}

.offerInfoContainerAnimation {
  background-color: #FDFDFD; // because animation is exported in that color, not white
}

.offerInnerContainer {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 0;

  h1 {
    line-height: 3rem;
    text-align: center;
    color: var(--primaryColor);
  }
}

.sortBlock>div {
  margin: 2rem 0 0 auto;
}

.progressBar {
  z-index: 1;
  margin-top: 35px;
  height: 15px;
  border-radius: 2.1rem;

  div[role="progressbar"] {
    background-color: var(--greenColor);
    border-radius: 2.1rem;
  }
}

.offerList {
  margin-block-start: 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.9rem;
}

.offerBtnContainer {
  display: flex;
  gap: 2.3rem;
  margin-block-start: 3.6rem;

  button:first-child:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }

  button:last-child:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }
}

.borrowerFooter {
  background-color: var(--bgColor);
  padding: 2.9rem;
}

.footerCol1 {
  align-self: flex-end;

  ul {
    display: flex;
    gap: 0.5rem;
    padding: 0px;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;

    li {
      a {
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: var(--primaryVariationTextColor);
        position: relative;
      }
    }
  }
}

.footerCol2 {
  text-align: center;
  cursor: pointer;

  p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--primaryVariationTextColor);
  }

  img {
    max-width: 76px;
  }
}

.title {
  @extend .fontBig;
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
}

.titleSearching {
  font-size: 2.4rem;
  line-height: 1.2;
  text-align: center;
  color: var(--primaryVariationTextColor);
}

.searchingOffersAnimation {
  background-image: url("../../public/assets/images/Lenders.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: none;
  width: 400px;
  aspect-ratio: 1.01; // +1 to remove weird line at the bottom
  margin: auto;
}

.invitationFormContainer {
  padding-inline: 2.9rem;
  margin-block-end: 5.4rem;
}

.invitationFormInner {
  max-width: 84.1rem;

  h1 {
    text-align: center;
    margin-block-start: 3.5rem;
  }

  p {
    color: var(--primaryVariationTextColor);
    text-align: center;
    padding: 20px;
  }
}

.brandLogo {
  text-align: center;

  img {
    max-width: 137px;
  }
}

.titleDtm {
  @extend .big;
  text-align: center;
}

.nextSteps {
  color: var(--darkTextColor);
  text-align: center;
  margin-top: 3rem;
}

.offersDisclaimer {
  @extend .fontInfoBig;
  text-align: center;
  text-wrap: balance;
  background-color: var(--bgColor);
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .offersContainer {
    background-color: var(--whiteColor);
  }

  .helpContainer {
    padding: 1.5rem 0rem;
  }

  .sortBlock {
    margin-block-start: 2rem;
  }

  .offerBtnContainer {
    button {
      max-width: 100% !important;
    }
  }

  .footerCol2 {
    display: none;
  }

  .searchingOffersAnimation {
    width: 100%;
    height: 100%;
  }
}