.contentContainer {
  margin-block-end: 2rem;
  padding-inline: 3.5rem;
  padding-left: 9.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  .tableContainer {
    overflow-x: auto;
    border-radius: 2rem;
    box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
    background-color: var(--whiteColor);
    padding: 0;

    >div {
      min-width: 1200px;
    }
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
