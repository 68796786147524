/* OptimizedForm.module.scss */

.optimizedFormContainer {
  padding: 40px;
  margin: 0 auto;

  button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  button:disabled {
    background-color: #aaa;
    opacity: 0.5;
    /* Make the button opaque */
    cursor: not-allowed;
    /* Indicate that the button is not clickable */
  }

  h1.title {
    color: var(--primaryVariationTextColor);
    font-weight: 700;
    line-height: 4rem;
    text-align: center;
    margin-bottom: 30px;
  }

  .button {
    cursor: pointer;
    max-width: 25rem;
    min-width: 17rem;
    background-color: var(--primaryColor);
    border: 2px solid var(--primaryColor);
    border-radius: 4rem;
    color: var(--whiteTextColor);
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    margin: 0;
    padding: 1rem 2rem;
    text-align: center;
    width: auto;
  }

  .button:hover {
    transform: scale(1.1);
    transition: all .2s ease-in-out;
  }

  .button:disabled {
    background-color: var(--primaryColor);
    opacity: 0.5;
    /* Make the button opaque */
    cursor: not-allowed;
    /* Indicate that the button is not clickable */
  }
}