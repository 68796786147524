.gapContainer {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.orContainer {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  hr {
    flex: 1 1 48%;
    margin: 0;
    color: var(--primaryVariationTextColor);
    border-width: 2px;
    opacity: 1;
  }

  h2 {
    flex-direction: 1 1 2%;
    text-align: center;
  }
}

.bulkCsvContainer {
  display: flex;
  gap: 3.5rem;
  padding: 1rem;

  >div {
    max-width: 50%;
    flex: 1 1 50%;

    &:nth-child(2) {
      text-align: right;
      align-self: flex-end;
    }
  }
}

.uploadLogoContainer {
  p {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--darkTextColor);
    margin: 0;
  }
}

.labelcsvContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  a {
    color: var(--primaryTextColor);
  }
}

.uploadContainer {
  margin-block-start: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-inline: 2rem;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  border: 0.1rem solid var(--darkTextColor);
  border-radius: 1rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--darkTextColor);

  label {
    background-color: transparent;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--primaryColor);
    cursor: pointer;
  }

  #choosenFile {
    margin-left: 0.3rem;
    font-family: sans-serif;
  }
}