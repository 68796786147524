@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap);

:root {
  --primaryColor: #5b53e6;
  --primaryTextColor: #5b53e6; // updated based on dark or light theme
  --primaryVariationColor: #4541b4; // functionally, this is the same as --primaryVariationColor30; #4542AD - this is supposed to be primaryVariant too
  --primaryVariationTextColor: #4541b4; // updated based on dark or light theme
  --primaryVariationColor40: #3B3894; // rgba(59, 56, 148, 1)
  /* primary color with 30% black overlay - for colors, that result in "dark mode", this is the same as primaryVariationColor  */
  --primaryBlack30Color: #4541b4;
  /* --blueColor */
  --darkTextColor: #2f2d6c;
  /* --darkBlue */
  --greenColor: #0EC192;
  --secondaryColor: #ffb31e; // 255, 179, 30
  /* --yellowColor */
  --bgColor: #f8f8ff; // 248, 248, 255
  --textColor: #ffffff;
  --whiteColor: #ffffff;
  --whiteTextColor: #ffffff; // updated based on dark or light theme
  --scrollColor: #c9c7ff; // 201, 199, 255
  --disableColor: rgba(47, 45, 108, 0.3); // #2f2d6c4c
  --whiteColor20: rgba(255, 255, 255, 0.2); // #ffffff33
  /*** 20% Opacity ***/
  --whiteColor60: rgba(255, 255, 255, 0.6); // #ffffff99
  /*** 60% Opacity ***/
  --whiteColor80: rgba(255, 255, 255, 0.8); // #ffffffcc
  /*** 80% Opacity ***/
  --whiteColor15: 0.8rem 0.8rem 2rem rgba(111, 106, 248, 0.15); // #6f6af826
  --blurColor: rgba(47, 45, 108, 0.15); // #2f2d6c26
  --primaryColor40: rgba(111, 106, 248, 0.4); // #6f6af866
  --primaryColor20: rgba(111, 106, 248, 0.2); // #6f6af833
  --primaryColor10: rgba(111, 106, 248, 0.1); // #6F6AF81A
  --primaryColor40Solid: rgba(193, 191, 247); // #c1bff7 - should be #E0DFFD
  --primaryColor30Solid: rgba(206, 204, 247); // #ceccf7
  --primaryColor20Solid: rgba(220, 219, 247); // #dcdbf7
  --primaryColor10Solid: rgba(233, 233, 247); // #e9e9f7
  --secondaryColor40Solid: rgba(255, 079, 30, 0.4); // #ff4f1e66
  --mainViolet20: rgba(99, 94, 247, 0.2); // #635ef733
  --greenColor: rgba(52, 197, 159); // #34c59f
  --greenColor80: rgba(52, 197, 159, 0.8); // #34c59fcc
  --greenColor30: rgba(52, 197, 159, 0.3); // #34c59f4c
  --alertColor: rgba(255, 138, 0); // #ff8a00
  --placeholderColor: rgba(47, 45, 108, 0.6); // #2f2d6c99
  --graphGreenColor: #95e0cd; // 149, 224, 205
  --graphGreenColor20: rgba(52, 197, 159, 0.2); // #34c59f33
  --fontDMSans: "DM Sans", sans-serif;
  --fontNotoSans: "Noto Sans", sans-serif;
  --emptyColor: #b7b4ff; // 183, 180, 255
  --redColor: #E45F85; // 228, 95, 133
  --logoMaxHeight: 3vw;
  --squareLogoMaxHeight: 4.5vw;
  --navigationBarMargin: calc(2.3rem + var(--logoMaxHeight));
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semiBold: 600;
  --bold: 700;
}


* {
  margin: 0;
  padding: 0;
  font-family: var(--fontDMSans);
  box-sizing: border-box;
  font-style: normal;
}

html {
  font-size: 62.5%;
}

#mini_portal_image svg text {
  user-select: none;
}

.pageTemplate {
  display: flex;
  flex-direction: column;
}

p {
  margin: 0;
  font-family: var(--fontDMSans);
  font-weight: 500;
}

h1,
h2,
h3 {
  color: var(--primaryVariationTextColor);
  font-family: var(--fontNotoSans);
}

a {
  text-decoration: none !important;
  font-family: var(--fontDMSans);
  color: var(--primaryTextColor);
}

img {
  width: 100%;
  height: auto;
}

span {
  font-size: 1.4rem;
  font-weight: 500;
}

p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.8rem;
  text-align: left;
}

/**** Globally used Classes ****/

.activeMenu {
  background-color: var(--whiteColor20);
}

.customTooltip {
  background-color: var(--primaryColor);
  border-radius: 1.7rem;
  padding: 0.5rem 1.5rem;

  p {
    margin: 0;
    color: var(--whiteTextColor);
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}

p {
  margin: 0;
  font-family: var(--fontDMSans);
  font-weight: 500;
}

h1 {
  font-size: 2.3rem !important;
  font-weight: var(--bold) !important;
}

h2 {
  font-size: 1.8rem !important;
  font-weight: var(--bold) !important;
}


h3 {
  font-size: 1.8rem !important;
  font-weight: var(--bold);
}

h4 {
  font-size: 1.6rem !important;
  font-weight: var(--bold) !important;
}

.h3M {
  font-weight: var(--medium);
}

.fontLabel {
  font-family: var(--fontDMSans);
  font-weight: var(--semiBold);
  font-size: 1.6rem;
}

.fontLabelM {
  font-family: var(--fontDMSans);
  font-weight: var(--medium);
  font-size: 1.6rem;
}

.fontTextMBig {
  font-family: var(--fontDMSans);
  font-weight: var(--medium);
  font-size: 1.5rem;
}

.fontText {
  font-family: var(--fontDMSans);
  font-weight: var(--semiBold);
  font-size: 1.4rem;
}

.fontTextM {
  font-family: var(--fontDMSans);
  font-weight: var(--medium);
  font-size: 1.4rem;
}

.fontTextReg {
  font-family: var(--fontDMSans);
  font-weight: var(--regular);
  font-size: 1.4rem;
}

.fontTextRegBig {
  font-family: var(--fontDMSans);
  font-weight: var(--regular);
  font-size: 1.5rem;
}

.fontBig {
  font-family: var(--fontNotoSans);
  font-weight: var(--bold);
  font-size: 3.2rem;
}

// TODO: combine .fontBig with .big and it's media queries - same for other fonts
.fontBig2 {
  font-family: var(--fontNotoSans);
  font-weight: var(--bold);
  font-size: 2.8rem;
  line-height: 2.8rem;
}

.fontInfoBig {
  font-family: var(--fontDMSans);
  font-weight: var(--regular);
  font-size: 1.2rem;
}

.fontInfo {
  font-family: var(--fontDMSans);
  font-weight: var(--regular);
  font-size: 1.1rem;
}

.fontMobile {
  font-family: var(--fontDMSans);
  font-weight: var(--semiBold);
  font-size: 1.3rem;
}

.fontMobile2 {
  font-family: var(--fontDMSans);
  font-weight: var(--bold);
  font-size: 1.4rem;
}

.fontMobileS {
  font-family: var(--fontDMSans);
  font-weight: var(--semiBold);
  font-size: 1.2rem;
}

.label {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
}


// this one is missing in figma
.menu {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
}

.smooth {
  &:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }
}

.info {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
}

.infoMedium {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.big {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4.5rem;
  font-family: var(--fontNotoSans);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  margin-block-start: var(--navigationBarMargin);
}

/****** Hiding Increment Decrement icon from Input type number *******/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: none;
}

#createModal {
  z-index: 2;
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  width: 100%;
  max-width: 470px;
}

.react-select-container input {
  height: auto !important;
}

.react-select-container img {
  width: 1rem !important;
}

#tooltip-top {
  opacity: 1;
}

#tooltip-top>.tooltip-inner {
  background-color: var(--whiteColor);
  color: var(--darkTextColor);
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 1.1rem;
  font-family: var(--fontDMSans);
  font-size: 1.2rem;
  padding: 1.5rem;
  max-width: 300px;
}

#tooltip-top>.tooltip-arrow {
  display: none;
}

.react-select-image__option .country-option span {
  color: var(--primaryTextColor);
}

.react-select-image__option .country-option span span {
  color: var(--primaryTextColor);
  font-weight: 500;
}

.react-select-image__option:hover .country-option span span,
.react-select-image__option--is-selected .country-option span,
.react-select-image__option--is-selected .country-option span span,
.react-select-image__option:hover .country-option span {
  color: var(--primaryTextColor);
}

.react-select-credit__option .country-option,
.react-select-credit__single-value .country-option {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 1.5rem;
}

.react-select-credit__option .country-option img,
.react-select-credit__single-value .country-option img {
  width: 19px !important;
  font-weight: 500;
  font-size: 1.5rem;
}

.recharts-legend-item-text {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-transform: capitalize !important;
  color: var(--darkTextColor) !important;
}

.recharts-legend-wrapper {
  ul {
    li {
      display: flex !important;
      align-items: center !important;
      gap: 0.3rem;

      &:first-child {
        margin-block-end: 0.5rem !important;
      }
    }
  }
}

.rdrMonthAndYearWrapper {
  button {
    background: none !important;

    i {
      display: none;
    }
  }

  .rdrPprevButton {
    position: relative;

    &:after {
      content: "";
      width: 10px;
      height: 11px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: var(--primaryColor);
      clip-path: polygon(0 50%, 100% 100%, 100% 0);
    }
  }

  .rdrNextButton {
    position: relative;

    &:after {
      content: "";
      width: 10px;
      height: 11px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: var(--primaryColor);
      clip-path: polygon(0 100%, 100% 50%, 0 0);
    }
  }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: var(--primaryVariationColor) !important;
}

.rdrDayHovered,
.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  border-color: var(--primaryVariationColor) !important;
  margin-bottom: -1px;
}


.rdrDayNumber {
  span {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}

.rdrMonthAndYearPickers {
  justify-content: space-between !important;
  gap: 1rem;

  .rdrMonthPicker,
  .rdrYearPicker {
    margin: 0;
    position: relative;

    &:after {
      content: "";
      width: 9px;
      height: 8px;
      background: var(--primaryColor);
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 2rem;
      clip-path: polygon(0 0, 50% 100%, 100% 1%);
    }
  }

  .rdrMonthPicker {
    flex: 1 1 60%;
  }

  .rdrYearPicker {
    flex: 1 1 40%;
  }

  select {
    background: var(--whiteColor) !important;
    padding: 0.9rem 1.5rem !important;
    border: 2px solid var(--primaryColor) !important;
    border-radius: 23px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--primaryTextColor) !important;
    width: 100% !important;
    text-align: left !important;
    position: relative;

    option {
      padding-block: 0.9rem;
    }
  }
}

.rdrDefinedRangesWrapper {
  border-right: none;

  .rdrStaticRanges {
    button {
      background-color: var(--whiteColor) !important;
      border-bottom: 0.1rem solid var(--primaryColor);
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--darkTextColor) !important;
      border-radius: 2rem 0 0 !important;
    }

    button:hover {
      transform: none !important;
    }
  }
}

.rdrDay:hover {
  transform: none !important;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  top: 0.3rem !important;
  bottom: 0.22em !important;
  left: -1px;
  right: -1px;
}


.rdrDayToday .rdrDayNumber span::after {
  display: none;
}

.rdrEndEdge {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  right: 0px;
}


.rdrStartEdge {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  left: 0px;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  right: 0px;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  left: 0px;
}

.rdrDayHovered,
.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  transform: none;
  transition: none;
}

.rdrMonthAndYearPickers select option:hover {
  background: var(--primaryColor20);
}

.rdrStaticRanges {
  border-right: 0.1rem solid var(--primaryColor) !important;
}

.rdrWeekDay {
  color: var(--darkTextColor);
  font-weight: 500;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
  color: white;
}

.rdrDayNumber span {
  color: var(--darkTextColor);
}

.rdrCalendarWrapper {
  padding: 0 16px;
  border-radius: 2rem 2rem 0 0;
}

.rdrInputRanges {
  .rdrInputRange {
    input {
      border-color: var(--primaryColor);
    }

    input,
    span {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--darkBlue);
    }
  }
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: var(--primaryColor20Solid) !important;
  color: var(--darkTextColor) !important;
}

.rdrStaticRangeLabel {
  padding: 21px 25px;
}

.rdrDefinedRangesWrapper {
  width: 13.5rem !important;
  border-radius: 2rem;
}

.rdrInputRanges {
  display: none;
}


.reportType_select__option {
  .country-option {
    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--primaryVariationTextColor);

      svg {
        width: 2.3rem;

        path {
          stroke: var(--primaryVariationColor);
        }
      }
    }
  }

  &:hover {
    .country-option {
      span {
        color: var(--primaryVariationTextColor);

        svg {
          path {
            stroke: var(--primaryVariationColor);
          }
        }
      }
    }
  }
}

.reportType_select__single-value {
  .country-option {
    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--darkTextColor);

      svg {
        width: 2.3rem;

        path {
          stroke: var(--primaryVariationColor);
        }
      }
    }
  }
}

.reportType_select__option--is-selected {
  .country-option {
    span {
      color: var(--primaryVariationTextColor);

      svg {
        path {
          stroke: var(--primaryVariationColor);
        }
      }
    }
  }
}

.tableBody .react-select-container {
  margin-top: 0px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
  width: 100%;
  padding-inline: 2rem;
  height: 45px;
  border: 0.1rem solid var(--darkTextColor);
  border-radius: 1rem;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--darkTextColor);
}

input.inputError {
  border-color: #ff3d3d !important;
}

.inputError {
  border: 0.1rem solid #ff3d3d !important;
  border-radius: 1rem;
}

.switch {
  --secondary-container: #3a4b39;
  --primary: #84da89;
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3em;
  height: 1.4em;

  input {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e2e2e2;
    transition: 0.2s;
    border-radius: 30px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 2rem;
    width: 2rem;
    border-radius: 2rem;
    left: 0.2rem;
    bottom: 0.2rem;
    background-color: #aeaaae;
    transition: 0.4s;
  }

  .alwaysOn {
    background-color: var(--primaryColor20);
  }

  .alwaysOn:before {
    background-color: var(--primaryColor);
  }

  input:checked+.slider::before {
    background-color: var(--primaryColor);
  }

  input:checked+.slider {
    background-color: var(--primaryColor20);
  }

  input:focus+.slider {
    box-shadow: 0 0 1px var(--primaryColor20);
  }

  input:checked+.slider:before {
    transform: translateX(1.9em);
  }
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.logoPreview {
  position: relative;
  border-radius: 2rem;
}

.errorText {
  color: #ff3d3d !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  width: 100% !important;
  position: absolute !important;
  bottom: -35px !important;
}

.noValue {
  color: #f3f3f3;
}

.grecaptcha-badge {
  visibility: hidden;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollColor);
}

/****Global Media Query *****/
@media only screen and (max-width: 990px) {
  .big {
    font-size: 3rem;
    line-height: 3.5rem;
    padding: 2rem 1rem;
  }
}


@media only screen and (max-width: 500px) {
  main {
    margin-block-start: var(--navigationBarMargin);
  }

  .big {
    font-size: 2rem;
    line-height: 3rem;
    padding: 2rem 1rem;
  }
}

.disabled {
  color: var(--disableColor) !important;
}