.crossIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  transform: rotate(45deg);
  cursor: pointer;
}

.popupHeader {
  color: var(--primaryVariationTextColor);
}

.inputBlock {
  display: flex;
  justify-content: flex-start;
  gap: 3.5rem;

  >div {
    max-width: 50%;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;

    //   gap: 1rem;
    label {
      color: var(--darkTextColor);
    }
  }
}

.appDateBtn {
  max-width: 100%;
  background: var(--whiteColor);
  border-radius: 1rem;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1.2rem 1.5rem;
  border: 0.1rem solid var(--darkTextColor);
  margin-block-start: 1rem;
}

.filterBtn {
  align-self: flex-end;

  button {
    margin: 0 0 0 auto !important;
  }
}

@media only screen and (max-width: 990px) {
  .popupContainer {
    position: absolute;
    transform: translateX(-50%);
    max-width: 48rem;
    max-height: 90vh;
  }

  .inputBlock {
    flex-direction: column;

    >div {
      max-width: 100%;
    }
  }

  .filterBtn {
    align-self: unset;
  }
}