.clickable {
  cursor: pointer;
}

.whiteCircle {
  background-color: white;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 20px;
}

.colorCircle {
  background-color: var(--primaryColor);
}

.bigCircle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
}

.rotate180deg {
  transform: rotate(180deg);
}