.partnerInvitationContainer {
  background-color: var(--bgColor);
  position: relative;
}

.helpContainer {
  padding: 1.5rem 2.5rem !important;

  button {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--primaryVariationTextColor);
    background-color: transparent;
    border: none;
    width: auto;
  }
}

.invitationFormContainer {
  padding-inline: 2.9rem;
  margin-block-end: 10rem;
}

.invitationFormInner {
  max-width: 84.1rem;

  h1 {
    text-align: center;
  }
}

.backIcon {
  svg {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 26px;
  }
}

.formBlock {
  margin-block-start: 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.formInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.5rem;
}

.formField {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;

  label {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--darkTextColor);
  }

  input,
  select {
    margin-block-start: 1rem;
  }

  input:focus,
  select:focus {
    outline: none;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    width: 17px;
    aspect-ratio: 1;
  }
}

.pop2 {
  margin-right: 20px;
  position: absolute;
  background: var(--whiteColor);
  box-shadow: 8px 8px 20px rgba(111, 106, 248, 0.15);
  padding: 15px 15px;
  border-radius: 1.1rem;
  top: -2rem;
  right: -2rem;
  width: 250px;
  z-index: 2;

  p {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin: 0;
  }

  ul {
    margin-block-start: 1rem;
  }
}

.labelcsvContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  a {
    color: var(--primaryTextColor);
  }
}

.uploadContainer {
  margin-block-start: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-inline: 2rem;
  justify-content: space-between;
  height: 45px;
  width: 100%;
  border: 0.1rem solid var(--darkTextColor);
  border-radius: 1rem;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--darkTextColor);
  background-color: var(--whiteColor);

  label {
    background-color: transparent;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--primaryTextColor);
    cursor: pointer;
  }

  #choosenFile {
    margin-left: 0.3rem;
    font-family: sans-serif;
  }
}

.logoContainer {
  background: url("../../../../public/assets/images/logobg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  min-height: 25.4rem;
}


.formBtnContainer {
  text-align: right;

  button:hover {
    transform: scale(1.1);
  }

  button:focus {
    outline: none;
  }
}

.nextBtnContainer {
  flex: 1;
  align-self: flex-end;
}

.finalBtnContainer {
  display: flex;
  justify-content: flex-end;
  gap: 2.5rem;

  button:first-child:hover {
    transform: scale(1.1);
  }
}

.locationContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.7rem;
  margin-block-start: 3.5rem;

  img {
    width: auto;
  }

  p {
    color: var(--primaryVariationTextColor);
    margin: 0;
    text-transform: capitalize;
  }
}

.editLocationContainer {
  margin-block-start: 0.8rem;
}

.orContainer {
  display: flex;
  align-items: center;
  margin-block: 3.5rem;
  gap: 0.8rem;

  hr {
    flex: 1 1 48%;
    margin: 0;
    color: var(--primaryVariationTextColor);
    border-width: 2px;
    opacity: 1;
  }

  h2 {
    flex-direction: 1 1 2%;
    text-align: center;
  }
}

.progressBar {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;

  div[role="progressbar"] {
    background-color: var(--greenColor);
    border-radius: 0px 2.1rem 2.1rem 0px;
  }
}

@media only screen and (max-width: 600px) {
  .helpContainer {
    button {
      float: right;
    }
  }

  .formInner {
    flex-direction: column;
    gap: 2.6rem;
  }

  .formField {
    width: 100%;
  }

  .uploadContainer {
    span {
      word-break: break-all;
    }
  }

  .formBtnContainer {
    button {
      max-width: 100% !important;
    }
  }

  .nextBtnContainer {
    width: 100%;
  }
}