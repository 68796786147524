@import 'src/styles/global.scss';

.infoCardWrapper {
  margin: 10px;
  flex-basis: 21%;
}

.infoCard {
  width: 160px;
  height: 126px;
  border-radius: 2.5rem;
  background-color: var(--whiteColor);
  box-shadow: 8px 8px 20px 0px rgba(99, 94, 247, 0.15);
}

.value {
  color: var(--primaryTextColor);
  text-align: center;
  // margin-top: 30px;
  padding-top: 30px;
}

.label {
  @extend .fontText;
  color: var(--darkTextColor);
  text-align: center;
  margin-top: 20px;
}

.empty {
  width: 160px;
  height: 0px;
  margin: 10px;
}

.disabled {
  background-color: var(--blurColor);
}

@media only screen and (min-width: 1500px) {
  .empty {
    // otherwise cards on big screens (> 2300px) are not aligned vertically
    display: none;
  }
}