@import 'src/styles/global.scss';

.dashHeaderContainer {
  background-color: var(--primaryVariationColor);
  padding: 1.5rem 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  max-width: calc(100% - 23.1rem);
  top: 0;
  z-index: 8;
}

.dashUserBlock {
  @extend .fontLabelM;

  color: var(--whiteTextColor);
  margin: 0;
  flex: 1 1 50%;
}

.dashItems {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 50%;
  gap: 1rem;
}

.dashSearchBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2.8rem;
  flex: 1 1 90%;

  input {
    background-color: var(--whiteColor80);
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--darkTextColor);
    text-align: right;
    max-width: 268px;

    &::placeholder {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--darkTextColor);
      opacity: 100%;
    }

    &:focus {
      outline: none;
    }
  }
}

.locationModelBlock,
.businessModelBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  p {
    @extend .fontLabelM;
    margin: 0;
    color: var(--whiteTextColor);
  }
}

.locationModelBlock {
  cursor: pointer;
}

.dashNotificationBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2.8rem;
  flex: 1 1 10%;

  svg {
    cursor: pointer;
  }
}

@media only screen and (max-width: 990px) {
  .dashUserBlock {
    flex: 1 1 40%;
  }

  .dashItems {
    flex: 1 1 70%;
    gap: 1.4rem;

  }

  .dashSearchBlock {
    gap: 2.8rem;
  }

  .dashNotificationBlock {
    gap: 0.8rem;
  }
}

@media only screen and (max-width: 1200px) {
  .dashHeaderContainer {
    max-width: calc(100% - 7rem);
  }
}