.action-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--whiteColor);
  height: 70px;
  width: 100%;
  margin-bottom: 40px;
  border-radius: 20px;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  color: var(--primaryVariationTextColor);
}

.action-card-text {
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 0;
}

.action-card-button {
  height: 40px;
  margin-right: 30px;
}