.loadingContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 9999;
  cursor: pointer;
  justify-content: center;
}

.imageContainer {
  position: absolute;
  text-align: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  text-align: center;
  text-transform: uppercase;
  font-size: medium;
  color: #4541b4;
  margin-top: 30px;
}