.title {
  margin-top: 1rem;
  text-align: center;
}

.message {
  text-align: center;

  span {
    color: var(--primaryTextColor);
  }
}