// not used anymore on 05 Jul 2024

.selectCss {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--primaryVariationTextColor);
  line-height: 1.3;
  padding: 1rem 2rem 1rem 0.5rem;
  width: 100%;
  max-width: 13rem;
  box-sizing: border-box;
  margin: 10px 0px 10px;
  text-align: left;
  border: 2px solid var(--primaryVariationColor);
  box-shadow: none;
  border-radius: 2em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("../../../public/assets/images/Arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 2.5rem top 50%, 0 0;
  background-size: .8rem auto, 100%;
  padding-left: 3rem;
  text-overflow: ellipsis;
}

.selectCss::-ms-expand {
  display: none;
}

.selectCss:hover,
.selectCss:active,
.selectCss:focus,
.selectCss:valid {
  border-color: var(--primaryVariationTextColor);
}


.selectCss option {
  font-weight: 500;
  font-size: 1.4rem;
  font-family: var(--fontDMSans);
  text-overflow: ellipsis;
}