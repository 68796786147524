.businessModelWrapper {
  // padding: 0rem 3rem 0rem 3rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3rem;
}

.title {
  color: var(--darkTextColor);
  margin-bottom: 3rem;
}

.businessModelRadio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
  margin-top: 15px;

  p {
    margin: 0px 0px 0px 0px;
  }

  p:first-child {
    color: var(--primaryVariationTextColor);
  }

  p:last-child {
    color: var(--darkTextColor);
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 990px) {
  .popupContainer {
    position: absolute;
    transform: translateX(-50%);
    max-height: 90vh;
  }
}