.tab-content-section-title {
  color: var(--primaryVariationTextColor);
  font-size: 18px;
  font-weight: 600;
  line-height: 22.5px;
}

.tab-content-body {
  color: var(--primaryVariationTextColor);
  margin-bottom: 30px;
}

.actions-parent {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: space-between;
}

.actions-child-left {
  margin: 5px;
}

.actions-child-right {
  margin: 5px;
}