.crossIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  transform: rotate(45deg);
  cursor: pointer;
}

.inviteFormContainer {
  margin-block-start: 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;


  label {
    color: var(--darkTextColor);
  }

  input {
    margin-block-start: 1rem;

    &:focus {
      outline: none;
    }
  }
}

.inviteFormRow {
  display: flex;
  gap: 3.5rem;

  >div {
    flex: 1 1 50%;
    position: relative;
  }
}

.disabledInput {
  label {
    color: var(--placeholderColor);
  }

  input {
    border-color: var(--placeholderColor) !important;
    color: var(--placeholderColor) !important;
    pointer-events: none;
    user-select: none;
  }
}

.uploadLogoContainer {
  position: relative;

  p {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--darkTextColor);
    margin: 0;
  }
}

.labelContainer {
  display: flex;
  gap: 1rem;
  position: relative;

  img {
    width: 17px;
    aspect-ratio: 1;
  }
}

.pop2 {
  position: absolute;
  background: var(--whiteColor);
  box-shadow: 2px 4px 8px var(--primaryColor20);
  padding: 15px 18px;
  border-radius: 0.5rem;
  top: -2rem;
  right: 8rem;
  width: 290px;
  z-index: 2;

  p {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin: 0;
  }

  ul {
    margin-block-start: 1rem;
  }
}

.uploadContainer {
  margin-block-start: 0.8rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-inline: 2rem;
  justify-content: space-between;
  height: 45px;
  width: 100%;
  border: 0.1rem solid var(--darkTextColor);
  border-radius: 1rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--darkTextColor);

  label {
    background-color: transparent;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--primaryTextColor);
    cursor: pointer;
  }

  #choosenFile {
    margin-left: 0.3rem;
    font-family: sans-serif;
  }
}

.btnContainer {
  text-align: right;
}

.uploadedLogo {
  // TODO: fix styles
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  height: 100%;
  background-color: var(--whiteColor);
  padding: 2rem;
  display: flex;
  flex-direction: column;

  img {
    max-height: 10rem;
    max-width: 25rem;
    width: auto;
    height: auto;
    margin: auto;
  }
}

.domain {
  margin-top: 22px;
  margin-left: 4px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  color: var(--darkTextColor);
}