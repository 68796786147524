.pageContainer {
  background-color: var(--bgColor);
  position: relative;
}

.helpContainer {
  padding: 2rem 2.9rem;
}

.invitationFormContainer {
  padding-inline: 2.9rem;
  margin-block-end: 5.4rem;
}

.invitationFormInner {
  max-width: 84.1rem;

  h1 {
    text-align: center;
  }
}

.policyContent {
  margin-block-start: 3.5rem;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    color: var(--darkTextColor);
    margin: 1.5rem 0 0;
  }
}

@media only screen and (max-width: 990px) {
  .invitationFormInner {
    padding-inline: 7.2rem;
  }
}

@media only screen and (max-width: 600px) {
  .helpContainer {
    padding: 1.5rem 2.5rem;

    .helpCol1 {
      display: none;
    }
  }

  .invitationFormInner {
    padding-inline: 0;
    padding-block-start: 0;
    background-color: transparent;
    box-shadow: none;
  }
}