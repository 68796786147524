@import 'src/styles/global.scss';

.status {
  @extend .fontTextM;
  text-overflow: ellipsis;
  text-align: center;
  color: var(--primaryVariationTextColor);
}

.deactivated {
  color: var(--primaryVariationTextColor);
}

.active {
  color: var(--greenColor);
}

.declined {
  color: var(--secondaryColor);
}

.pending {
  color: var(--primaryTextColor);
}