@import 'src/styles/global.scss';

.businessModelWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: -3rem;
}

.title {
  @extend .big;
  color: var(--darkTextColor);
  margin-bottom: 3rem;
  text-align: center;
}

.subtitle {
  @extend .h3M;
  text-align: center;
}

@media only screen and (max-width: 990px) {
  .popupContainer {
    position: absolute;
    transform: translateX(-50%);
    max-height: 90vh;
  }
}