.tableDataContainer {
  border-radius: 2rem;

  >div {
    min-width: 78rem !important;

    div:nth-child(2) {
      >div {
        div:last-child {
          text-align: center;

          button {
            max-width: 14rem !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .popupContainer {
    max-width: 90%;
    max-height: 90vh;
  }
}