.tab {
  align-items: center;
  background-color: var(--primaryColor20Solid);
  border-color: var(--primaryColor40Solid);
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.tab:last-child {
  border-right-width: 0;
}

.tab.active {
  background-color: #F8F8FF;
  border-color: var(--primaryColor40Solid);
  border-width: 0;
  color: var(--primaryTextColor);
}

.tab-title {
  color: var(--primaryVariationTextColor);
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}