.dateRangeContainer {
  position: relative;
}

.dateRangeBlock {
  max-width: 26.6rem;
  margin: 0 0 0 auto;
  padding: 0.9rem 0;
  border: 2px solid var(--primaryColor);
  border-radius: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: var(--whiteColor);
  cursor: pointer;
}

.dateRange {
  span {
    color: var(--primaryVariationTextColor);
  }
}

.dateRangeModal {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 55px;
  box-shadow: var(--whiteColor15);
  background: var(--whiteColor);
  border-radius: 2rem;
  padding: 0;

  h2 {
    padding: 2rem 0 0 0;
    text-align: center;
  }
}

.dateRangeBtn {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  padding-inline: 1.7rem;
  margin-left: 1rem;
  margin-right: 1rem;

  button {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    width: 100px;
  }
}

.dateRangeBtnVariant {
  padding-inline: 0;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 0;

  button {
    width: 120px;
  }

  div {
    &:first-child {
      max-width: 12rem;
      flex: 1 1 12rem;
    }

    &:last-child {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      padding-inline: 2rem;
    }
  }
}