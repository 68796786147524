.progressbarcontainer {
  width: 100%;
  height: 20px;
  position: absolute;
  top: 60px;
  left: 0px;
  color: black;
  font-size: 1.7rem;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
  background-color: var(--whiteColor);
  overflow: hidden;
  box-shadow: (0px 1px 3px rgba(111, 106, 248, 0.25));
  ;
}

.progressbar {
  height: 100%;
  background-color: var(--greenColor);
  border-radius: 2.1rem;
  animation: progress-animation 2s linear infinite;
}

@keyframes progress-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.progressbar.active {
  animation-play-state: running;
}

.text {
  margin-left: 10px;
  position: absolute;
  top: 0;
  left: 10px;
  color: white;
  font-size: 1.5rem;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.show {
  opacity: 1;
}