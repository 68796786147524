input::-ms-reveal,
input::-ms-clear {
  display: none;
}


.loginPageContainer {
  background-color: var(--bgColor);
  position: relative;
  padding-block: 6.5rem;
  padding-inline: 2.9rem;
}

.innerContainer {
  h1 {
    font-weight: 700 !important;
    font-size: 3.2rem !important;
    line-height: 4rem !important;
    text-align: center;
  }

  h2 {
    font-weight: 700 !important;
    font-size: 2.4rem !important;
    line-height: 4rem !important;
    text-align: center;
  }
}

.backIcon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 26px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  max-width: 45rem;
  margin: 4.5rem auto 0;
}

.formField {
  input:focus {
    outline: none;
  }

  input {
    margin-top: 1rem;
  }

  label {
    color: var(--darkTextColor);
  }
}

.passwordInputField {
  position: relative;

  img {
    position: absolute;
    width: 20px;
    right: 4%;
    top: 46%;
    cursor: pointer;
    z-index: 1;
    background-color: transparent;
  }
}

.passwordMinRequirements {
  p {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--primaryVariationTextColor);
  }

  ul {
    li {
      color: var(--primaryVariationTextColor);
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
}

.formHelp {
  display: flex;
  justify-content: space-between;

  a {
    color: var(--primaryTextColor);
  }
}

.submitBtnContainer {
  button:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }
}

.msgContainer {
  text-align: center;

  h1 {
    margin-block-end: 3.5rem;
  }

  button:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }
}