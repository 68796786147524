@import 'src/styles/global.scss';

:global(#applicationSummaryWrapper) {
  display: flex;
  flex-direction: column;
  height: calc(100% - 6rem);
  position: absolute;
  margin-top: -3rem;
  right: 0;
  box-shadow: -2px 2px 20px 0px rgba(99, 94, 247, 0.15);

  transform: translateX(450px);
  transition: all 0.4s ease;
}

:global(#applicationSummaryWrapper.applicationSummaryActive) {
  transform: translateX(0px);
}

.card {
  width: 45rem;
  background-color: var(--whiteColor);
  right: 0;
  padding: 30px;
  padding-top: 40px;
  flex: 2;
  position: relative;

  h2 {
    text-align: center;
  }
}

.card2 {
  width: 45rem;
  background-color: var(--primaryColor20Solid);
  right: 0;
  padding: 30px;
  padding-top: 40px;
  flex: 5;
  position: relative;
  border-bottom-left-radius: 20px;

  h2 {
    text-align: center;
  }
}

.offerLenders {
  color: var(--darkTextColor);
  text-align: center;
}

.offerDetailsWrapper {
  text-align: center;
}

.offerDetails {
  @extend .fontMobile2;
  color: var(--darkTextColor);
  display: inline;
}

.important {
  color: var(--primaryTextColor);
}

.moreInfoWrapper {
  width: 100%;
  text-align: center;
  margin-block-start: 3rem;
}

.moreInfoButton {}