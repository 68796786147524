.popup {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  width: 100%;
  max-width: 700px;
  background-color: var(--whiteColor);
  border-radius: 2rem;
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
}

.popupInner {
  max-height: 90vh;
  left: 50%;
  position: fixed;
  top: 100px;
  transform: translateX(-37%);
  padding: 1rem 3.5rem 4.5rem 3.5rem;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  max-width: 765px;
  margin: 0 auto;
  background-color: var(--whiteColor);
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  width: 100%;
}

.confetti {
  background-image: url("../../../public/assets/images/thanksGif.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}