@import 'src/styles/global.scss';

.crossIcon {
  position: absolute;
  top: 10px;
  right: 12px;
  transform: rotate(45deg);
  cursor: pointer;
}

.pageContainer {
  background-color: var(--bgColor);
  position: relative;
  padding-inline: 2.9rem;
  padding-top: 1rem;
  margin-top: 0rem;
}

.innerContainer {
  h1 {
    text-align: center;
  }
}

.backIcon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 26px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  //max-width: 33rem;
  margin: 0 auto;
  margin-bottom: 30px;
}

.formField {
  input:focus {
    outline: none;
  }

  input {
    margin-top: 1rem;
  }

  label {
    color: var(--darkTextColor);
  }
}

.passwordInputField {
  position: relative;

  img {
    position: absolute;
    width: 20px;
    right: 4%;
    top: 46%;
    cursor: pointer;
    z-index: 1;
    background-color: transparent;
  }
}

.errorText {
  color: #ff3d3d !important;
  font-size: 1.3rem;
  font-weight: 500;
  width: 100%;
  position: absolute;
  bottom: -35px;
  overflow-wrap: break-word;
}

.errorTextOne {
  color: #ff3d3d !important;
  font-size: 1.3rem;
  font-weight: 500;
  width: 100%;
  position: absolute;
  bottom: -35px;
  left: 10px;
}

.errorTextArea {
  top: 100px !important;
}

.formFieldBetween {
  width: 3.5rem;
  align-items: center;
  text-align: center;
}

.or {
  @extend .fontLabel;
  line-height: 20px;
  text-align: center;
  margin-bottom: 15px;
}

.formBlock {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.formInner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 3.5rem;
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  label {
    @extend .fontLabelM;
    line-height: 2.2rem;
    color: var(--darkTextColor);
  }

  input,
  select {
    margin-block-start: 1rem;
  }

  input:focus,
  select:focus {
    outline: none;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;

  img {
    width: 17px;
    aspect-ratio: 1;
  }

  img {
    width: 17px;
    aspect-ratio: 1;
  }
}

.pop2 {
  margin-right: 20px;
  position: absolute;
  background: var(--whiteColor);
  box-shadow: 8px 8px 20px rgba(111, 106, 248, 0.15);
  padding: 15px 15px;
  border-radius: 1.1rem;
  top: -2rem;
  right: -2rem;
  width: 250px;
  z-index: 2;

  p {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.4rem;
    margin: 0;
  }

  ul {
    margin-block-start: 1rem;
  }
}

.passwordInputField {
  position: relative;

  img {
    position: absolute;
    width: 20px;
    right: 4%;
    top: 44%;
    cursor: pointer;
    z-index: 1;
    background-color: transparent;
  }
}

.uploadLogoContainer {
  background-color: var(--whiteColor);

  p {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--darkTextColor);
    margin: 0;
  }
}

.uploadContainer {
  margin-block-start: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-inline: 2rem;
  justify-content: space-between;
  height: 45px;
  width: 100%;
  border: 0.1rem solid var(--darkTextColor);
  border-radius: 1rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  color: var(--darkTextColor);

  label {
    background-color: transparent;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: var(--primaryTextColor);
    cursor: pointer;
  }

  #choosenFile {
    margin-left: 0.3rem;
    font-family: sans-serif;
  }
}

.formInnerThird {
  gap: 1rem;
  align-items: flex-end;
}

.ssnInner {
  justify-content: center;
}

.logoContainer {
  background: var(--whiteColor);
  box-shadow: 0.8rem 0.8rem 2rem var(--primaryColor20);
  border-radius: 2rem;
  min-height: 25.4rem;
  text-align: center;
}

.formBtnContainer {
  text-align: right;

  button:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }

  button:focus {
    outline: none;
  }
}

.nextBtnContainer {
  flex: 1;
  align-self: flex-end;
}

.finalBtnContainer {
  display: flex;
  justify-content: flex-end;
  gap: 3.6rem;

  button:first-child:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }
}

.formBtnContainer {
  text-align: right;

  button {
    max-width: 25rem !important;
    min-width: 17rem !important;
  }

  button:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }
}

.btnAferText {
  margin-top: -80px;
}

.formHelp {
  display: flex;
  justify-content: space-between;

  a {
    color: var(--primaryTextColor);
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--darkTextColor);
  }

  /*button {
    min-width: 17rem !important;
  }*/
}

.formBtnContainer {
  button:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }
}

.msgContainer {
  text-align: center;

  h1 {
    margin-block-end: 3.5rem;
  }

  button:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.1);
  }
}

.two_buttons_wrapper {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .pageContainer {
    background-color: #66000000;
    position: relative;
    padding-inline: 0;
    padding-top: 1rem;
    margin-top: 0rem;
  }

  .backIcon {
    top: -16px;
    left: -4px;
  }

  .formBtnContainer {
    text-align: center;
  }

  .formHelp {

    p {
      text-align: center;
    }
  }

  .formBtnContainer {
    text-align: center !important;
  }

  .formInner {
    flex-direction: column;
  }

  .formField {
    width: 100%;
  }
}

.submitBtnContainer {
  text-align: center;

  button {
    width: 100%;
  }
}

.preface {
  color: var(--darkTextColor);
  font-size: 1.5rem;
}

.tripleInputWithLabel {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  input {
    margin-block-start: 0.5rem;
  }
}

.tripleInputWrapper {
  display: flex;
  gap: 1.5rem;
}

.tripleInputLabel {
  font-weight: var(--medium) !important;
  font-size: 1.5rem !important;
}

.tripleInputErrors {
  display: flex;
  flex-direction: column;
}

.tripleInputError {
  height: 15px;
  position: relative;

  p {
    top: 0px !important;
  }
}