.searchBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-block: 3.4rem;

  div {
    flex: 1 1 50%;

    &:last-child {
      text-align: right;
    }
  }
}

.tableContainer {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 990px) {
  .popupContainer {
    position: absolute;
    transform: translateX(-50%);
    max-height: 90vh;
  }
}